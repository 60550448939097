import React, { Component } from 'react';

import "../carousel/slick/slick.css";
import "../carousel/slick/slick-theme.css"
import Slider from 'react-slick';
import quoteicon from '../../Images/testimonialimages/quote.png';
import quoteicon1 from '../../Images/testimonialimages/quote1.png';
import quoteicon2 from '../../Images/testimonialimages/quote2.png';
import img1 from '../../Images/testimonialimages/testimonial_img1.png';
import img2 from '../../Images/testimonialimages/testimonial_img2.png';
import img3 from '../../Images/testimonialimages/testimonial_img3.png';
import img4 from '../../Images/testimonialimages/testimonial_img4.png';
import line from '../../Images/testimonialimages/line.png';
import location from '../../Images/testimonialimages/location.png'
import quote from '../../Images/testimonialimages/commentquote.png'

const linestyle = {
    height: '18%',
    width: '100%',
    marginTop: '16px'
}

const testimonialboxstyle = {
    border: '1px solid rgba(245, 245, 245, 1)',
    boxShadow: '0px -4px 4px 0px rgba(0, 0, 0, 0.15), 0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    background: 'rgba(255, 255, 255, 1)',
    //background:'red',
    width: '100%',
    //paddingLeft: '19px',
    // maxWidth: '372px',
    paddingTop: '100px',
    marginTop: '-75px',
    minHeight: '365px',
    position: 'relative',
    display:'flex',
    flexDirection:'column',
    alignItems:'center'
}

const testimonialcommentstyle = {
    paddingRight: '30px',
    paddingLeft: '27px',
    fontSize: '14px',
    lineHeight: '24px',
    opacity: '0.6',
    width:'100%'
}

const usernamestyle = {
    textAlign: 'center',
    fontWeight: '700',
    fontSize: '16px',
    lineHeight: '18px',
    color: '#000000',
    paddingBottom: '8px',
}

const userlocationboxstyle = {
    background: '#F3F3F3',
    width: '87%',
    paddingTop: '8px',
    paddingBottom: '7px',
}

const locationimagestyle = {
    width: '20px', height: '20px', marginBottom: '-20px'
}

const locationtextstyle = {
    marginLeft: '12px'
}

class TestimonialsComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            slidesToShow: 2.5, // Default value
        };
        this.updateSlidesToShow = this.updateSlidesToShow.bind(this);
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateSlidesToShow);
        this.updateSlidesToShow(); // Initial call to set slidesToShow
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateSlidesToShow);
    }

    updateSlidesToShow() {
        if (window.innerWidth >= 1000 && window.innerWidth <= 1200) {
            this.setState({ slidesToShow: 2.2 });
        } else if (window.innerWidth > 1200 && window.innerWidth < 1400) {
            this.setState({ slidesToShow: 2.5 });
        } else if(window.innerWidth > 1400){
            this.setState({ slidesToShow: 3 });
        } else {
            this.setState({ slidesToShow: 2.5 });
        }
    }

    render() {
        const settings = {
            autoplay: true,
            autoplaySpeed: 5000,
            arrows: true,
            centerMode: false,
            dots: false,
            infinite: true,
            slidesToShow: this.state.slidesToShow,
            slidesToScroll: 1,
            variableWidth: true,
            adaptiveHeight: false,
        };

        return (
            <>
                <section style={{ paddingBottom: '20px', paddingTop:'25px', marginTop: '20px', background: '#F2F5F9' }}>
                            <div style={{display:'flex', paddingLeft:'5%'}}>
                                <h2 className="title" style={{marginBottom:'0px'}}><span>Discover What Our Customers Say</span></h2>
                                <span style={{width:'48%', marginLeft:'19px'}}><img src={line} style={linestyle}></img></span>
                            </div>
                </section>

                <section style={{ background: '#F2F5F9', paddingLeft: '5px', paddingRight: '5px', overflow: 'visible', overflowX: 'hidden', marginBottom: '22px', height: '510px' }} className='testimonial'>

                    <Slider {...settings}>
                        <div >
                            <div style={{ display: 'grid', justifyContent: 'center', background: '#F2F5F9' }}>
                                <img style={{ maxHeight: '30px', zIndex: '100', position: 'relative', marginBottom: '-38px', left: '75%' }} src={quoteicon} />
                                <img style={{ maxHeight: '150px', zIndex: '50' }} src={img1} />
                            </div>

                            <img style={{ marginTop: '-23px', marginLeft: '55px', zIndex: '70', position: 'relative' }} src={quote} />
                            <div style={testimonialboxstyle}>

                                <div className='row' style={{ fontSize: '18px', lineHeight: '20px', paddingBottom: '10px', width:'100%' }}>
                                    <div className='col-12'>Easy Process - Seamless Support! </div>
                                </div>

                                <div className='row' style={{ ...testimonialcommentstyle }}>
                                    Thanks Team IndiaMART.
                                    You provided the best support and co-operated with me throughout the loan process. I will suggest all my references to use your services and avail loans from your platform.
                                </div>

                                <div style={{ position: 'absolute', bottom: '0', width: '91%' }}>
                                    <div className='row' >
                                        <div className='col-12 d-flex justify-content-center' style={usernamestyle}>
                                            Pritesh Kumar Pandya
                                        </div>

                                    </div>

                                    <div className='row' style={{ paddingBottom: '15px', justifyContent:'center' }}>
                                        <div className='col-12 d-flex justify-content-center' style={userlocationboxstyle}>
                                            <img style={locationimagestyle} src={location} />
                                            <span style={locationtextstyle}>Gandhinagar, Gujarat</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div >
                            <div style={{ display: 'grid', justifyContent: 'center', background: '#F2F5F9' }}>
                                <img style={{ maxHeight: '30px', zIndex: '100', position: 'relative', marginBottom: '-38px', left: '75%' }} src={quoteicon1} />
                                <img style={{ maxHeight: '150px', zIndex: '50' }} src={img2} />
                            </div>

                            <img style={{ marginTop: '-23px', marginLeft: '55px', zIndex: '70', position: 'relative' }} src={quote} />
                            <div style={testimonialboxstyle}>

                                <div className='row' style={{ fontSize: '18px', lineHeight: '20px', paddingBottom: '10px' }}>
                                    <div className='col-12'> Digital Loan - Minimal Documentation </div>
                                </div>

                                <div className='row' style={{ ...testimonialcommentstyle }}>
                                    The entire loan experience was very simple - minimal documentation, digital process. Best assistance provided by the team. Overall, great experience.
                                </div>

                                <div style={{ position: 'absolute', bottom: '0', width: '91%' }}>
                                    <div className='row' >
                                        <div className='col-12 d-flex justify-content-center' style={usernamestyle}>
                                            Vinit Garg
                                        </div>

                                    </div>

                                    <div className='row' style={{ paddingBottom: '15px', justifyContent:'center' }}>
                                        <div className='col-12 d-flex justify-content-center' style={userlocationboxstyle}>
                                            <img style={locationimagestyle} src={location} />
                                            <span style={locationtextstyle}>Meerut, Uttar Pradesh</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div >
                            <div style={{ display: 'grid', justifyContent: 'center', background: '#F2F5F9' }}>
                                <img style={{ maxHeight: '30px', zIndex: '100', position: 'relative', marginBottom: '-38px', left: '75%' }} src={quoteicon2} />
                                <img style={{ maxHeight: '150px', zIndex: '50' }} src={img3} />
                            </div>
                            <img style={{ marginTop: '-23px', marginLeft: '55px', zIndex: '70', position: 'relative' }} src={quote} />
                            <div style={testimonialboxstyle}>

                                <div className='row' style={{ fontSize: '18px', lineHeight: '20px', paddingBottom: '10px' }}>
                                    <div className='col-12'> Best Experience - Constant Support </div>
                                </div>

                                <div className='row' style={{ ...testimonialcommentstyle }}>
                                    I had an outstanding experience with IndiaMart for my business loan. There was constant support from the IndiaMart team and the loan provider.
                                </div>

                                <div style={{ position: 'absolute', bottom: '0', width: '91%' }}>
                                    <div className='row' >
                                        <div className='col-12 d-flex justify-content-center' style={usernamestyle}>
                                            Amresh Kumar
                                        </div>

                                    </div>

                                    <div className='row' style={{ paddingBottom: '15px', justifyContent:'center' }}>
                                        <div className='col-12 d-flex justify-content-center' style={userlocationboxstyle}>
                                            <img style={locationimagestyle} src={location} />
                                            <span style={locationtextstyle}>Tilhar, Uttar Pradesh</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div >
                            <div style={{ display: 'grid', justifyContent: 'center', background: '#F2F5F9' }}>
                                <img style={{ maxHeight: '30px', zIndex: '100', position: 'relative', marginBottom: '-38px', left: '75%' }} src={quoteicon} />
                                <img style={{ maxHeight: '150px', zIndex: '50' }} src={img4} />
                            </div>
                            <img style={{ marginTop: '-23px', marginLeft: '55px', zIndex: '70', position: 'relative' }} src={quote} />
                            <div style={testimonialboxstyle}>

                                <div className='row' style={{ fontSize: '18px', lineHeight: '20px', paddingBottom: '10px' }}>
                                    <div className='col-12'>Smooth Process - Best Offers</div>
                                </div>

                                <div className='row' style={{ ...testimonialcommentstyle }}>
                                    I am extremely pleased with the loan services provided by IndiaMart. I found the entire process to be incredibly smooth and easy.
                                </div>

                                <div style={{ position: 'absolute', bottom: '0', width: '91%' }}>
                                    <div className='row' >
                                        <div className='col-12 d-flex justify-content-center' style={usernamestyle}>
                                            Rolamin Sadhukha
                                        </div>

                                    </div>

                                    <div className='row' style={{ paddingBottom: '15px', justifyContent:'center' }}>
                                        <div className='col-12 d-flex justify-content-center' style={userlocationboxstyle}>
                                            <img style={locationimagestyle} src={location} />
                                            <span style={locationtextstyle}>Gobardanga, West Bengal</span>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>

                    </Slider >

                </section >
            </>
        );
    } ''
}

export default TestimonialsComponent;