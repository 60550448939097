import React, { Component } from 'react';

import feesncharges from '../../Images/knowloanimages/Fees_and_charges_BG.jpg'


function stdnumeration(x) {
    return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
}

function getLoanBracket(val)
{
    let bracket = 0;
    if(val>0 && val < 500000){bracket = 0;}
    else if(val>500000 && val < 3000000){bracket = 500000;}
    else if(val>3000000 && val < 10000000){bracket = 3000000;}
    else if(val>10000000){bracket = 10000000;}
    return bracket;
}
function getinterestrate()
{
    var rate_value;
    if(document.getElementById('r1').checked){
    rate_value = document.getElementById('r1').value;

    }else if(document.getElementById('r2').checked){
        rate_value = document.getElementById('r2').value;

    }else if(document.getElementById('r3').checked){
        rate_value = document.getElementById('r3').value;
    }

    return rate_value;
}

function getseccover()
{
    var cover_value;
    if(document.getElementById('c1').checked){
        cover_value = document.getElementById('c1').value;

    }else if(document.getElementById('c2').checked){
        cover_value = document.getElementById('c2').value;

    }else if(document.getElementById('c3').checked){
        cover_value = document.getElementById('c3').value;
    }

    return cover_value;
}

function emiCalculator(p, r, t) 
{ 
    let emi; 
    // one month interest 
    r = r/(12 * 100); 
    // one month period 
    t = t*12;  
    emi = (p * r * Math.pow(1 + r, t))/(Math.pow(1 + r, t) - 1); 
    return Math.round(emi,2); 
}

const scrollToID = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -80; // Adjust the offset as needed
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };


class FindLoanComponent extends Component
{
    constructor(props) {
        super(props);
    
        this.loanAmtRangeRef = React.createRef();
        this.CalcloanAmt = React.createRef();
        this.CalcIntRate = React.createRef();
        this.CalcTenure = React.createRef();
      }

    findproduct = () => {

        var securityCover = ['Low','Medium','High'];
        var loanAmount = ['0','500000','3000000'];
        var interestRates = ['Low','Medium','High'];
        var product = ['Secured Business Loan / Loan Against Property','Collateral-free Business Loan '];

        var sc = securityCover;
        var la = loanAmount;
        var ir = interestRates;

        var combinations = [];
        combinations[product['0']] = [
            [sc[0],la[1],ir[0]].join('~'),
            [sc[0],la[0],ir[0]].join('~'),
            [sc[0],la[2],ir[0]].join('~'),
            [sc[1],la[0],ir[0]].join('~'),
            [sc[1],la[1],ir[0]].join('~'),
            [sc[1],la[1],ir[1]].join('~'),
            [sc[1],la[2],ir[0]].join('~'),
            [sc[2],la[0],ir[0]].join('~'),
            [sc[2],la[1],ir[0]].join('~'),
            [sc[2],la[1],ir[1]].join('~'),
            [sc[2],la[2],ir[0]].join('~'),
            [sc[2],la[2],ir[1]].join('~'),

            [sc[0],la[0],ir[1]].join('~'),
            [sc[0],la[1],ir[1]].join('~'),
            [sc[0],la[2],ir[1]].join('~'),
            [sc[0],la[2],ir[2]].join('~'),
            [sc[1],la[0],ir[1]].join('~'),
            [sc[1],la[1],ir[2]].join('~'),
            [sc[1],la[2],ir[1]].join('~'),
            [sc[1],la[2],ir[2]].join('~'),
            [sc[2],la[0],ir[1]].join('~'),
            [sc[2],la[1],ir[2]].join('~'),
            [sc[2],la[2],ir[2]].join('~'),
        ];

        combinations[product['1']] = [
            [sc[0],la[0],ir[2]].join('~'),
            [sc[0],la[1],ir[2]].join('~'),
            [sc[1],la[0],ir[2]].join('~'),
            [sc[2],la[0],ir[2]].join('~'),
        ];

        const loanAmtrangeValue = this.loanAmtRangeRef.current.value;

        const outputElement = document.getElementById('la_value');
        outputElement.innerHTML = '₹'+stdnumeration(loanAmtrangeValue);

        let newSecurityCover = getseccover();
        const loanamt = document.getElementById('la_value').innerHTML;
        let newLoanAmount = getLoanBracket(loanamt.replace(/₹|,/g, ''));
        let newInterestRate = getinterestrate();
        var productToCompare = [newSecurityCover,newLoanAmount,newInterestRate].join('~');
        for(var product in combinations) 
        {
            const is_in_array = combinations[product].includes(productToCompare);
            if(is_in_array)
            {
                var newProduct = product; 
            }
        }

        const loansuggestion = document.getElementById('loansuggestion');
        loansuggestion.innerHTML = newProduct;

    };


    calcLoan = () => {
        
        const CalcloanAmtValue = this.CalcloanAmt.current.value;
        const CalcIntRateValue = this.CalcIntRate.current.value;
        const CalcTenureValue = this.CalcTenure.current.value;

        const calcloanamount = document.getElementById('calcla_value');
        calcloanamount.innerHTML = '₹'+stdnumeration(CalcloanAmtValue);

        const calcintrateval = document.getElementById('calcint_value');
        calcintrateval.innerHTML = CalcIntRateValue;

        const calctenureval = document.getElementById('calcten_value');
        calctenureval.innerHTML = CalcTenureValue;

        const totalEmi = emiCalculator(CalcloanAmtValue, CalcIntRateValue, CalcTenureValue); 

        const totalAmount = totalEmi*12*CalcTenureValue;
        const totalInterest = totalAmount - CalcloanAmtValue;

        const calcEmiVal = document.getElementById('EMIVal');
        calcEmiVal.innerHTML = '₹'+stdnumeration(totalEmi);

        const calcTotIntval = document.getElementById('TotInt');
        calcTotIntval.innerHTML = '₹'+stdnumeration(totalInterest);

        const calcTotAmtVal = document.getElementById('TotAmt');
        calcTotAmtVal.innerHTML = '₹'+stdnumeration(totalAmount);





    };


    render()
    {
        return (
            <>
                <section style={{marginBottom: '20px', marginLeft: '5px'}}>
                    <div className="container sec05 text-center"
                    style={{backgroundImage: `url(${feesncharges})`, margin: 'auto', minHeight: '260px', backgroundSize: 'contain', backgroundPosition: 'center top' , backgroundRepeat: 'no-repeat', padding :'20px'}}>
                    <div className="row">
                        <h2 className="title"><span>Fees & Charges </span> </h2>
                        <div className=" col-12">
                        The business loan fees and charges may differ depending upon the lender and a particular case. <br/> Generally,
                        the fees and charges depend on the applicant's profile, creditworthiness, type of business, <br/> desired loan
                        amount, interest rate, repayment tenure, existing loans and several other factors.
                        </div>
                    </div>
                    </div>
                </section>

                <section style={{background: '#F3F9FF'}}>
                    <div className="container sec04 text-center">
                    <div className="row" style={{justifyContent: 'center'}}>
                        <h2 className="title">Find Your <span>Ideal Loan</span> & Calculate Your Payments </h2>
                        <div className="col-lg-6 loanfit">
                        <div className="loan_card_heading">Which loan fits you?</div>
                        <div className="row">
                            <div className="col-6 loanTitle"> Loan Amount</div>
                            <div className="col-6 loanVal loanamountval "><span id="la_value" className="amtVal">&#8377;5,00,000</span></div>

                            <div className="col-12 range">
                            <input type="range" labels-list="loan_amount_labels"
                                className="form-control-range range_elem loan_fit_elem loan_amount" min="0" max="10000000" defaultValue="500000"
                                step="100000" ref={this.loanAmtRangeRef} onChange={this.findproduct}/>

                            </div>
                            <ul className="range-labels loan_amount_labels">
                            <li>0L</li>
                            <li style={{textAlign: 'right'}}>1Cr</li>
                            </ul>
                        </div>
                        

                        <div className="loanTitle"> Interest Rate </div>

                        <div id="interest_rates" className="row" style={{textAlign: 'left',marginBottom: '0'}}>
                            <div className="col-4">
                            <input type="radio" className="interest_rate loan_fit_elem" id="r1" name="rate" value="Low" onChange={this.findproduct}
                                style={{accentColor: '#49A399'}}/>
                            <span className="radiobutton1_text_position"> Low </span>
                            </div>
                            <div className="col-4">
                            <input type="radio" className="interest_rate loan_fit_elem" id="r2" name="rate" value="Medium" onChange={this.findproduct}
                                style={{accentColor: '#49A399'}}/>

                            <span className="radiobutton2_text_position"> Medium </span>
                            </div>
                            <div className="col-4">
                            <input type="radio" className="interest_rate loan_fit_elem" id="r3" name="rate" value="High" onChange={this.findproduct} defaultChecked
                                style={{accentColor: '#49A399'}}/>
                            <span className="radiobutton3_text_position"> High </span>
                            </div>
                        </div>


                        <div className="row rateTxt" style={{textAlign: 'left'}}>
                            <div className="col-4">(8%-13%)</div>
                            <div className="col-4">(13%-19%)</div>
                            <div className="col-4">(19% Above)</div>
                        </div>

                        <div className="loanTitle"> Security Cover (Property Value/Loan Amount) </div>
                        <div id="sec_cover" className="row" style={{textAlign: 'left',marginBottom: '45px'}}>
                            <div className="col-4">
                            <input type="radio" className="security_cover loan_fit_elem" id="c1" name="cover" value="Low" onChange={this.findproduct} defaultChecked
                                style={{accentColor: '#49A399'}}/>
                            <span className="c_radiobutton1_text_position"> Low </span>
                            </div>
                            <div className="col-4">
                            <input type="radio" className="security_cover loan_fit_elem" id="c2" name="cover" value="Medium" onChange={this.findproduct} 
                                style={{accentColor: '#49A399'}}/>
                            <span className="c_radiobutton2_text_position"> Medium </span>
                            </div>
                            <div className="col-4">
                            <input type="radio" className="security_cover loan_fit_elem" id="c3" name="cover" value="High" onChange={this.findproduct}
                                style={{accentColor: '#49A399'}}/>
                            <span className="c_radiobutton3_text_position"> High </span>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col-8 sugg">
                            <span className="suggest_head">Loan Suggestion</span> <br/>
                            <b><span id='loansuggestion' className="suggest_result product_result">Loan Against Property</span></b>
                            </div>
                            <div className="col-3">
                            <button className="explore_btn" onClick={() => scrollToID('lenders')}>Explore </button>
                            </div>
                        </div>



                        </div>
                        <div className="col-lg-6 loanfit" style={{marginLeft:'2%'}}>
                    
                        <div className="loan_card_heading">EMI Calculator</div>
                        <div className="row">
                            <div className="col-6 loanTitle"> Loan Amount </div>
                            <div className="col-6 loanVal loanamountval "><span id='calcla_value' className="amtVal">&#8377;5,00,000</span></div>

                            <div className="col-12 range">
                            <input type="range" labels-list="loan_amount_labels"
                                className="form-control-range range_elem loan_fit_elem loan_amount" min="0"
                                max="10000000" defaultValue="500000" step="100000" ref={this.CalcloanAmt} onChange={this.calcLoan}/>
                            </div>
                            <ul className="range-labels loan_amount_labels">
                            <li>0L</li>
                            <li style={{textAlign: 'right'}}>1Cr</li>
                            </ul>
                        </div>
                        <div className="row">
                            <div className="col-6 loanTitle"> Interest Rate </div>
                            <div className="col-6 loanVal loanamountval"><span id='calcint_value' className="intVal intrst_val_ch">16</span><span
                                className="intIco">%</span></div>

                            <div className="col-12 range">
                            <input type="range" labels-list="loan_amount_labels"
                                className="form-control-range range_elem loan_fit_elem intrst_val emi_rate emi_elem" min="12" max="36"
                                defaultValue="16" step="0.5" ref={this.CalcIntRate} onChange={this.calcLoan}/>
                            </div>
                            <ul className="range-labels loan_amount_labels">
                            <li>12%</li>
                            <li style={{textAlign: 'right'}}>36%</li>
                            </ul>
                        </div>


                        <div className="row">
                            <div className="col-6 loanTitle"> Loan Tenure </div>
                            <div className="col-6 loanVal loanamountval  "><span id='calcten_value' className="intVal loan_time">2</span><span
                                className="intIco">Yr</span></div>

                            <div className="col-12 range">
                            <input type="range" labels-list="loan_amount_labels"
                                className="form-control-range range_elem loan_fit_elem loan_time_val emi_time emi_elem" min="1" max="5"
                                defaultValue="2" step="1" ref={this.CalcTenure} onChange={this.calcLoan}/>
                            </div>
                            <ul className="range-labels loan_amount_labels">
                            <li>1 Yr</li>
                            <li style={{textAlign: 'right'}}>5 Yr</li>
                            </ul>
                        </div>


                        <div className="row align-center">
                            <div className="col-3 sugg" style={{width: '30%'}}>
                            <span className="monthlyemi">Monthly EMI</span> <br/>
                            <b><span id='EMIVal'>₹24,482</span></b>
                            </div>

                            <div className="col-3 sugg" style={{width: '30%'}}>
                            <span className="totinterest">Total Interest</span> <br/>
                            <b><span id='TotInt' >₹87,568</span></b>
                            </div>

                            <div className="col-3 sugg" style={{width: '30%'}}>
                            <span className="totamt">Total Amount</span> <br/>
                            <b><span id='TotAmt'>₹587,568</span></b>
                            </div>
                        </div>

                        </div>
                    </div>
                    </div>
                </section>

                {/* <section>
                    <div className="container sec04 text-center">
                    <div className="row">
                        <h2 className="title"><span>Your Applications</span></h2>
                        <div className="col">Complete your loan application form where you left. Hasslefree. Anytime anywhere. </div>
                        <h2 className="title"><span className="appl">Coming Soon...</span></h2>
                    </div>
                    </div>
                </section> */}
            </>
        );
    }
}


export default FindLoanComponent;