import React, { Component } from 'react'

import HeaderComponent from '../header/HeaderComponent'
import IciciIntroComponent from '../lenderintro/IciciIntroComponent'
import IciciProductsComponent from '../products/IciciProductsComponent'
import IciciFAQsComponent from '../lenderFAQs/IciciFAQsComponent'
import FooterComponent from '../footer/FooterComponent'

export class IciciLenderPage extends Component {
  render() {
    return (
      <>
        <HeaderComponent />
        <IciciIntroComponent />
        <IciciProductsComponent />
        <IciciFAQsComponent />
        <FooterComponent />
      </>
    )
  }
}

export default IciciLenderPage