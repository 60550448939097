import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import {Modal} from 'react-bootstrap';

import {getGlid, clicktracking, gatrack} from '../../utils';

import feature1 from '../../Images/productsimages/feature1.png'
import feature2 from '../../Images/productsimages/feature2.png'
import feature3 from '../../Images/productsimages/feature3.png'
import feature4 from '../../Images/productsimages/feature4.png'
import ModalComponent from '../common/ModalComponent';

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    background: '#EDF5FC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px'
};

const buttonstyle = {
    width: '199px',
    height: '54px',
    flexShrink: '0',
    borderRadius: '10.595px',
    background: 'var(--Brand1, #2E3192)',
    borderStyle: 'none',
    color: '#FFF',
    fontFamily: 'Arial',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    marginTop: '-10px'
};

const emiIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#FCF1ED',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const tenureIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#FCF9ED',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const pfeeicon = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#ECECEC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const eligcriteria = {
    paddingTop: '35px',
    textTransform: 'capitalize',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '100%',
    textAlign: 'left',
    paddingLeft: '32px',
    paddingBottom: '24px'
}

const featuretextstyle = {
    marginBottom: '10px',
    color: '#626262',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '147.023%'
}

const featurevaltextstyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '147.023%'
}

const docstextstyle = {
    textAlign: 'left',
    marginLeft: '40px',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '186%' /* 33.48px */
}

function iciciglidtracker() {

    var glid = getGlid();

    var url = "https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus";

    if (glid) {
        url += ("&glid=" + glid);

        window.open(url, '_blank');
    }
    else {
        window.open(url, '_blank');
    }
}

function getcookieready(dataarray) {
    const formattedString = Object.entries(dataarray)
        .map(([key, value]) => `${key}=${value}`)
        .join('|');

    return formattedString;
}

export class IciciProductsComponent extends Component {
    constructor(props){
        super(props);
        this.state = {show:false};
    }
    logincheck = (vendor_name, vendor_url) => {
        var glid = getGlid();

        if (!glid) {
            this.setState({show:true});
            // window.open(vendor_url, '_blank');
            gatrack('Non Logged in User','Popup Opened - Other Webpages','Click');
        }
        else {
            iciciglidtracker();
            gatrack('Apply for loan', vendor_name, 'Click');
            clicktracking(vendor_name);
        }

    }

    toggleModal = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
    }

    render() {
        return (
            <>
                <div className='container sec03 text-center' style={{ paddingTop: '65px' }}>
                    <div className='row' style={{ marginLeft: '10px' }}>
                        <div className='col-12' style={{ textAlign: 'left', paddingBottom: '48px' }}>
                            <span>Types of Products</span>
                        </div>
                    </div>

                    <section style={{ background: '#F5FAFF', paddingBottom: '50px' }} >

                        <div className='row' style={{ border: '1px #2E3192', borderStyle: 'none none solid none', paddingTop: '59px', textAlign: 'left', paddingLeft: '40px', marginLeft: '15px', marginRight: '10px' }}>
                            <div className='col-6 productname' style={{ paddingBottom: '35px', marginLeft: '-40px', textTransform: 'none' }}>
                                <strong>InstaOD Plus by ICICI</strong>
                            </div>
                            <div className='col-6' style={{ textAlign: 'right' }}>
                                <button style={buttonstyle} onClick={() => this.logincheck('ICICI Business Loan', 'https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus')}>Apply Now</button>
                            </div>
                        </div>
                        <div style={{ marginTop: '32px' }}>

                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="d-flex" style={{ textAlign: 'left', position: 'relative', left: '-13px' }}>
                                            <div style={expandingIconStyle}>
                                                <img src={feature1} alt="Int Rate Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Interest Rate:<br /> <strong>16%</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="d-flex " style={{ textAlign: 'left' }}>
                                            <div style={tenureIconStyle}>
                                                <img src={feature3} alt="Tenure Icon" style={{ paddingTop: '8px' }} />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Tenure: <br /> <strong>12 months</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="d-flex " style={{ textAlign: 'left' }}>
                                            <div style={pfeeicon}>
                                                <img src={feature4} alt="PPfees Icon" style={{ paddingTop: '8px' }} />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px', whiteSpace: 'nowrap' }}>
                                                Processing Fee: <br /> <strong>2%</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: '0px' }}>
                            <div style={eligcriteria}>
                                Required Documents
                            </div>
                            <div style={docstextstyle}>
                                <li>We just need your 12 months bank statement</li>
                            </div>
                        </div>


                    </section>


                </div>

                {
                    this.state.show && 
                    <ModalComponent url={'https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus&glid='} handleClose={this.toggleModal} />
                }
            </>
        )
    }
}

export default IciciProductsComponent