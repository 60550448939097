import React, { Component } from 'react'

import HeaderComponent from '../header/HeaderComponent'
import LenderIntroComponent from '../lenderintro/LenderIntroComponent'
import ProductsComponent from '../products/ProductsComponent'
import FooterComponent from '../footer/FooterComponent'

export class AboutUsPage extends Component {
  render() {
    return (
      <>
      <div >
      <HeaderComponent/>
      <LenderIntroComponent/>
      <ProductsComponent/>
      <FooterComponent/>
      </div>
      </>
    )
  }
}

export default AboutUsPage;