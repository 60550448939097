import React, { Component } from 'react'
import '../../App.css';

import axios from 'axios';
import Cookies from 'universal-cookie';

import {geturldata} from '../../utils';


import banner from '../../Images/mainImages/mainBannerImage.png'
import startingIcon from '../../Images/commonIcons/startingIcon.png'
import expandingIcon from '../../Images/commonIcons/expandingIcon.png'
import capitalIcon from '../../Images/commonIcons/capitalIcon.png'
import assetIcon from '../../Images/commonIcons/assetIcon.png'
import machineryIcon from '../../Images/commonIcons/machineryIcon.png'
import noCollateralIcon from '../../Images/unsecuredBLIcons/noCollateralIcon.png'
import shorterLoanIcon from '../../Images/unsecuredBLIcons/shorterLoanIcon.png'
import lowerLoanIcon from '../../Images/unsecuredBLIcons/lowerLoanIcon.png'
import guaranteesIcon from '../../Images/unsecuredBLIcons/guaranteesIcon.png'
import higherRatesIcon from '../../Images/unsecuredBLIcons/higherRatesIcon.png'
import worthinessIcon from '../../Images/unsecuredBLIcons/worthinessIcon.png'
import termLoanImage from '../../Images/mainImages/termLoanNewImage.jpg'
import overDraftImage from '../../Images/mainImages/overdraftNewImage.jpg'
import collateralRequiredLogo from '../../Images/securedBLIcons/collateralRequiredLogo.png'
import higherAmountsLogo from '../../Images/securedBLIcons/higherAmountsLogo.png'
import longerRepayementLogo from '../../Images/securedBLIcons/longerRepayementLogo.png'
import lowerRatesLogo from '../../Images/securedBLIcons/lowerRatesLogo.png'
import prepayementLogo from '../../Images/securedBLIcons/prepayementLogo.png'
import riskCollateralLogo from '../../Images/securedBLIcons/riskCollateralLogo.png'
import variableRateLogo from '../../Images/securedBLIcons/variableRateLogo.png'
import propertyLoanImage from '../../Images/mainImages/propertyLoanNewImage.webp'

import indifiLogo from '../../Images/lenderimages/Indifi_logo-01.png'
import iciciLogo from '../../Images/lenderimages/ICICI_Bank_Logo-01.jpg'
import offer from '../../Images/lenderimages/offer-svgrepo-com.svg'
import iiflLogo from '../../Images/lenderimages/IIFL-New-Common-logo.png'
import lendingkartLogo from '../../Images/lenderimages/lendingkartlogo-.svg'
import creditenableLogo from '../../Images/lenderimages/CreditEnable_Logo_Dark_Blue.png'
import andromedaLogo from '../../Images/lenderimages/Andromeda.jpeg(2)(1).jpg'
import psbLogo from '../../Images/lenderimages/PSB59-Logo-for-IndiaMart.png'
import pirimid from '../../Images/lenderimages/Ignosis_Logo_Horizontal.svg'
import creditmantriLogo from '../../Images/lenderimages/CM-Logo-1920.png'
import oneinfinityLogo from '../../Images/lenderimages/OI_logo.jpg'
import loantapLogo from '../../Images/lenderimages/HQ_LoanTap_logo_.png'

const bannerStyle = {
    borderRadius: '40px',
    background: `linear-gradient(326deg, rgba(26, 84, 171, 0.51) 18.13%, rgba(40, 182, 191, 0.14) 129.59%), 
                linear-gradient(180deg, rgba(26, 84, 138, 0.00) 0%, rgba(14, 44, 73, 0.60) 100%), 
                linear-gradient(326deg, rgba(0, 6, 15, 0.51) 18.13%, rgba(40, 182, 191, 0.14) 129.59%), 
                linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(14, 44, 73, 0.60) 100%)`,
    width: '100%',
    height: '344px'
};

const paraStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px'
};

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    background: '#EDF5FC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px'
};

const unsecuredBLStyle = {
    fontFamily: 'Arial',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '32px',
    textTransform: 'capitalize',
    background: 'linear-gradient(91deg, #1A54AB 0%, #28B6BF 103.91%)',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    WebkitTextFillColor: 'transparent'
}

const termLoanstyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '28px'
}


function clicktracking(vendor_name) {

    const cookie = new Cookies();

    var glid = '';

    let visitorData = cookie.get('ImeshVisitor');
    if (visitorData) {
        visitorData = visitorData.split("|");

        let sd = [];
        visitorData.map((data) => {
            sd = data.split("=")
            if (sd[0] == "glid") {
                glid = sd[1]
            }
        })
    }

    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    var getlenderorderData = {
        "glid" : glid,
        "functionName" : "StoreLenderOrderData",
        "lenderorder" : 'NA',
        "action" : "Apply Now Click-SubPages",
        "ApplyNow" : vendor_name,
        "device" : "Desktop" 
    };

    var formData = new FormData();
    for (var key in getlenderorderData) {
        formData.append(key, getlenderorderData[key]);
    }

    if(glid)
    {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout:10000
            }).then(function (response) {
            })
    }

}

function iciciglidtracker() {

    const cookie = new Cookies();

    var glid ='';

    let visitorData = cookie.get('ImeshVisitor');
    if(visitorData)
    {
        visitorData = visitorData.split("|");

        let sd = [];
        visitorData.map((data) => { 
            sd = data.split("=")
            if(sd[0] == "glid"){
                glid = sd[1]
            }
        })
    }

    var url = "https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus";

    if (glid) {
        url += ("&glid=" + glid);

        window.open(url, '_blank');
    }
    else {
        window.open(url, '_blank');
    }
}
	

function gatrack(category, action, label, value = null) {

    const cookie = new Cookies();

    var glid ='';

    let visitorData = cookie.get('ImeshVisitor');
    if(visitorData)
    {
        visitorData = visitorData.split("|");

        let sd = [];
        visitorData.map((data) => { 
            sd = data.split("=")
            if(sd[0] == "glid"){
                glid = sd[1]
            }
        })
    }

    if (value) {
        window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label, 'eventValue': value });
    }
    else {
        window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label, 'eventValue': glid });
    }
}


export class ProductsPageBody extends Component {

    logincheck = (vendor_name, vendor_url) =>
    {
        const cookie = new Cookies();

        var glid='';

        let visitorData = cookie.get('ImeshVisitor');
        if(visitorData)
        {
            visitorData = visitorData.split("|");
    
            let sd = [];
            visitorData.map((data) => { 
                sd = data.split("=")
                if(sd[0] == "glid"){
                    glid = sd[1]
                }
            })
        }
    

        if(!glid)
        {
            window.open(vendor_url,'_blank');
        }
        else
        {
            if(vendor_name == "ICICI Business Loan")
            {
                iciciglidtracker();
                gatrack('Apply for loan',vendor_name,'Click');
                clicktracking(vendor_name);
            }
            else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap"  || vendor_name == "OneInfinity")
            {
                geturldata(vendor_name, '');
                var product_name = vendor_name + " Business Loan"
                gatrack('Apply for loan',product_name,'Click');
                clicktracking(product_name);                              
            }
            else
            {
                gatrack('Apply for loan',vendor_name,'Click');
                clicktracking(vendor_name);
                window.open(vendor_url,'_blank');
            } 
        }
        
    }

    redirectBasedOnAddress = () =>
    {
        var currentAddress = window.location.href;

        if (currentAddress.includes('loans.indiamart.com')) {
            window.location.href = 'https://loans.indiamart.com';
        } else if (currentAddress.includes('dev-loans.indiamart.com')) {
            window.location.href = 'https://dev-loans.indiamart.com';
        }
    }
    render() {
        return (
            <>
                <section>
                    <div className='row' style={{ marginTop: '52px', marginLeft: '48px', marginRight: '48px' }}>
                        <div className='col-12' style={{textAlign:'center'}} > <img src={banner} style={{width:'85%',height:'100%'}}/> </div>
                    </div>
                </section>
                <section>
                    <div style={{ marginTop: '40px', marginLeft: '100px', marginRight: '74px' }}>
                        <div style={{ marginRight: '26px' }}>
                            <p style={paraStyle}>Business loan is a financial assistance that provides capital to entrepreneurs for various needs. These loans are offered by leading banks and NBFCs and can be used for</p>
                        </div>
                        <div style={{ marginTop: '32px' }}>

                            <div className="container" style={{padding:'0px'}}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div>
                                                <img src={startingIcon} alt="Starting Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Starting a business
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div style={expandingIconStyle}>
                                                <img src={expandingIcon} alt="Expanding Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Expanding Operations
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id='capitalIconStyle'>
                                                <img src={capitalIcon} alt="Capital Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Working Capital Requirements
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id='assetIconStyle'>
                                                <img src={assetIcon} alt="Asset Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Asset or Inventory Purchase
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '33px' }}>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id="machineryIconStyle">
                                                <img src={machineryIcon} alt="Machinery Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Upgrading Machinery
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style={{ marginTop: '40px' }}>
                            <div style={unsecuredBLStyle}>Unsecured Business Loan</div>
                            <div style={{ marginRight: '26px', marginTop: '20px' }}>
                                <p style={paraStyle}>Unsecured loan is a type of borrowing without requiring collateral. These loans are provided based on the health of the business and the owners creditworthiness.</p>
                            </div>
                            <div className='viewOfferBox' style={{ marginTop: '13px' }}>
                                <a className='viewOfferText' style={{textDecoration:'none', cursor:'pointer'}} onClick={()=> this.redirectBasedOnAddress()}>View offers Exclusively for you</a>
                            </div>
                            <p className='keyFeaturesBL' style={{ marginTop: '42px' }}>Here are some key characteristics of unsecured business loans:</p>

                            <div style={{ marginTop: '37px' }}>
                                <div className="container" style={{padding:'0px'}}>
                                    <div className="row">
                                        <div className="col-lg-3">
                                            <div className="d-flex align-items-center">
                                                <div id='noCollateralStyle'>
                                                    <img src={noCollateralIcon} alt="No Colletral" />
                                                </div>
                                                <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                    No Collateral
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="d-flex align-items-center">
                                                <div style={expandingIconStyle}>
                                                    <img src={shorterLoanIcon} alt="Shorter Loan" />
                                                </div>
                                                <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                    Shorter Loan Terms
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="d-flex align-items-center">
                                                <div id='capitalIconStyle'>
                                                    <img src={lowerLoanIcon} alt="Lower Loan" />
                                                </div>
                                                <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                    Lower Loan Amounts
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="d-flex align-items-center">
                                                <div id='assetIconStyle'>
                                                    <img src={guaranteesIcon} alt="Personal Guarantees" />
                                                </div>
                                                <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                    Personal Guarantees
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row" style={{ marginTop: '33px' }}>
                                        <div className="col-lg-3">
                                            <div className="d-flex align-items-center">
                                                <div id="machineryIconStyle">
                                                    <img src={higherRatesIcon} alt="Higher Rates" />
                                                </div>
                                                <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                    Higher Interest Rates
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-lg-3">
                                            <div className="d-flex align-items-center">
                                                <div id="worthinessIconStyle">
                                                    <img src={worthinessIcon} alt="Worthiness" />
                                                </div>
                                                <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                    Credit
                                                    Worthiness
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='section3'>
                            <div className="container" style={{ padding: '40px' }}>
                                <div className="row">
                                    <div className="col-7" style={{ padding: '0px', width: '61.333333%', marginTop: '19px' }}>
                                        <div style={{ paddingLeft: '11px' }}>
                                            <div style={termLoanstyle}>1.1 Term Loan</div>
                                            <p style={{ ...paraStyle, marginTop: '24px' }}>A term loan is a type of business loan that provides a lump sum of money to a borrower upfront, which is repaid over a predetermined period with regular installments.</p>
                                            <p style={{ ...paraStyle, fontSize: '14px' }}>Lenders providing Term Loan facility :</p>
                                            <div style={{ marginRight: '75px' }}>
                                                <div className="container" style={{ padding: '0px' }}>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <div id='lendersLogoBoxStyle'><img  src={indifiLogo} style={{width:'90%', cursor:'pointer'}} onClick={() => this.logincheck('Indifi Business Loan','https://www.indifi.com/associate/indiamart')} /></div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div id='lendersLogoBoxStyle'><img src={iiflLogo} style={{width:'100px', height:'40px', cursor:'pointer'}} onClick={() => this.logincheck('IIFL Business Loan','https://iifl-loans.finbox.in/?partnerCode=II_YATOYJ')} /></div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div id='lendersLogoBoxStyle'><img src={lendingkartLogo} style={{height:'32px', cursor:'pointer'}} onClick={() => this.logincheck('Lendingkart Business Loan','https://www.lendingkart.com/business-loan/sme/Indiamart?lksrc=c3JjPWluZGlhbWFydCZ0eXBlPUNQJnJlZmlkPWluZGlhbWFydDAwMQ')}/></div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div id='lendersLogoBoxStyle'><img src={creditenableLogo} style={{width:'100px', height:'35px', cursor:'pointer'}} onClick={() => this.logincheck('CreditEnable Unsecured','https://www.creditenable.com/in/sme-business-loan/unsecured-business-loans/indiamart-sme-business-loans-v1?')}/></div>
                                                        </div>
                                                    </div>


                                                    <div className="row" style={{ marginTop: '8px' }}>
                                                        <div className="col-3">
                                                            <div id='lendersLogoBoxStyle'><img src={creditmantriLogo} style={{width:'90%',cursor:'pointer'}} onClick={() => this.logincheck('CreditMantri','https://www.creditmantri.com/Indiamart/?utm_content=alliance-lp&alliance_lender=Indiamart&utm_campaign=alliances&utm_source=Indiamart_CoBrandedPage&utm_term=alliance_indiamart')}/></div>
                                                        </div>
                                                        <div className="col-3">
                                                            <div id='lendersLogoBoxStyle'><img src={oneinfinityLogo} style={{width:'60px', cursor:'pointer'}} onClick={() => this.logincheck('OneInfinity','https://complementor.oneflo.in/complementor-widget/bil/CONN4128778344?')}/></div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5" style={{ padding: '0px', width: '38.666667%' }}>
                                        <div>
                                            <img src={termLoanImage} style={{ height: '340px', width: '85.874%', borderRadius: '24px', flexShrink: '0', marginLeft: '43px' }}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container" style={{ paddingLeft: '40px', paddingRight: '40px' }}>
                                <div className="row" style={{ margin: '0px' }}>
                                    <div className="col-4" style={{ padding: '0px' }}>
                                        <div>
                                            <img src={overDraftImage} style={{ height: '340px', width: '102%', borderRadius: '24px', flexShrink: '0' }}></img>
                                        </div>
                                        <div style={{position:'relative', fontSize:'8px',top:'-14px',marginLeft:'18px'}}>Image Credit: Lendingkart</div>
                                    </div>
                                    <div className="col-8" style={{ marginTop: '28px', padding: '0px' }}>
                                        <div style={{paddingLeft:'20px'}}>
                                            <div style={termLoanstyle}>1.2 Overdraft</div>
                                            <p style={{ ...paraStyle, marginTop: '24px' }}>
                                                An overdraft is a financial arrangement offered by banks and financial institutions that allows a business or an individual to withdraw more money from a bank account than is available, effectively going into a negative balance.
                                            </p>
                                            <p style={{ ...paraStyle, fontSize: '14px' }}>Lenders providing Term Loan facility :</p>
                                            <div style={{width:'40%' }}>
                                                <div className="container" style={{ padding: '0px' }}>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div id='lendersLogoBoxStyle'><img src={lendingkartLogo} style={{height:'32px', cursor:'pointer'}} onClick={() => this.logincheck('Lendingkart Business Loan','https://www.lendingkart.com/business-loan/sme/Indiamart?lksrc=c3JjPWluZGlhbWFydCZ0eXBlPUNQJnJlZmlkPWluZGlhbWFydDAwMQ')}/></div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div id='lendersLogoBoxStyle'><img src={iciciLogo} style={{width:'90%',cursor:'pointer'}} onClick={() => this.logincheck('ICICI Business Loan','https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus')}/></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ marginTop: '40px' }}>
                            <div style={unsecuredBLStyle}>Secured Business Loan</div>
                            <p style={{ ...paraStyle, marginTop: '32px' }}>Secured business loan is a type of loan where a business borrows money and provides collateral as security to the lender. Collateral is an asset that the lender can claim and sell if the borrower defaults on the loan, which reduces the risk for the lender.</p>
                            <div className='viewOfferBox' style={{ marginTop: '16px' }}>
                                <a className='viewOfferText' style={{textDecoration:'none', cursor:'pointer'}} onClick={()=> this.redirectBasedOnAddress()}>View offers Exclusively for you</a>
                            </div>
                        </div>
                        <p className='keyFeaturesBL' style={{ marginTop: '32px', marginBottom: '32px' }}>Here are the key features and details of secured business loans:</p>
                        
                        <div>
                            <div className="container" style={{padding:'0px'}}>
                                <div className="row">
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id='collateralRequiredStyle'>
                                                <img src={collateralRequiredLogo} alt="Colletral Required" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Collateral Required
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id='lowerRatesStyle'>
                                                <img src={lowerRatesLogo} alt="Lower Rates" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Lower Interest Rates
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id='capitalIconStyle'>
                                                <img src={higherAmountsLogo} alt="Higher Loan" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Higher Loan Amounts
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id='assetIconStyle'>
                                                <img src={variableRateLogo} alt="Variable Rate" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Fixed or Variable Interest Rates
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '33px' }}>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id="machineryIconStyle">
                                                <img src={riskCollateralLogo} alt="Risk Collateral" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Risk to Collateral
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id="worthinessIconStyle">
                                                <img src={longerRepayementLogo} alt="Longer Terms" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Longer Repayment Terms
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3">
                                        <div className="d-flex align-items-center">
                                            <div id="prepayementIconStyle">
                                                <img src={prepayementLogo} alt="Prepayement" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Prepayment Options
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id='section4'>
                            <div className="container" style={{ padding: '40px' }}>
                                <div className="row">
                                    <div className="col-7" style={{ padding: '0px', width: '61.333333%'}}>
                                        <div style={{ paddingLeft: '11px', marginTop:'62px'}}>
                                            <div style={termLoanstyle}>Loan Against Property</div>
                                            <p style={{ ...paraStyle, marginTop: '24px' }}>Loan Against Property (LAP) is a type of loan where a borrower uses their residential or commercial property as collateral to secure the loan.</p>
                                            <p style={{ ...paraStyle, fontSize: '14px' }}>Lenders providing Term Loan facility :</p>
                                            <div style={{width:'40%'}}>
                                                <div className="container" style={{ padding: '0px' }}>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div id='lendersLogoBoxStyle'><img src={andromedaLogo} style={{width:'65%',cursor:'pointer'}} onClick={() => this.logincheck('Andromeda Business Loan','https://apnapaisa.com/campaign/19/loans/short-term-loan/5?AdGroupId=P-Indiamart-BL-Affiliate&buyerid=chhayat52322194')} /></div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div id='lendersLogoBoxStyle'><img src={psbLogo} style={{width:'100%', cursor:'pointer'}} onClick={() => this.logincheck('OPL Business Loan','https://www.psbloansin59minutes.com/indiamart')}/></div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-5" style={{ padding: '0px', width: '38.666667%' }}>
                                        <div>
                                            <img src={propertyLoanImage} style={{ height: '340px', width: '85.874%', borderRadius: '24px', flexShrink: '0', marginLeft: '43px' }}></img>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default ProductsPageBody