import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import {Modal} from 'react-bootstrap';

import {getGlid, clicktracking, gatrack} from '../../utils';

import feature1 from '../../Images/productsimages/feature1.png'
import feature2 from '../../Images/productsimages/feature2.png'
import feature3 from '../../Images/productsimages/feature3.png'
import feature4 from '../../Images/productsimages/feature4.png'
import ModalComponent from '../common/ModalComponent';

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    background: '#EDF5FC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px'
};

const buttonstyle = {
    width: '199px',
    height: '54px',
    flexShrink: '0',
    borderRadius: '10.595px',
    background: 'var(--Brand1, #2E3192)',
    borderStyle: 'none',
    color: '#FFF',
    fontFamily: 'Arial',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    marginTop : '-10px'
};

const emiIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#FCF1ED',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const tenureIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#FCF9ED',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const pfeeicon = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#ECECEC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const eligcriteria = {
    paddingTop:'35px',
    textTransform: 'capitalize',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '100%',
    textAlign:'left',
    paddingLeft:'32px',
    paddingBottom:'24px' 
}

const featuretextstyle = {
    marginBottom: '10px',
    color: '#626262',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '147.023%' 
}

const featurevaltextstyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '147.023%'
}

const docstextstyle = {
    textAlign:'left', 
    marginLeft:'40px',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '186%' /* 33.48px */
}

function glidtracker(lender_name) {

    var glid = getGlid();

    if(lender_name == 'icici')
    {
        var url = "https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus";
    }
    if(lender_name == 'poonawalla')
    {
        var url = "https://poonawallafincorp.com/poonawalla-indiamart-business-loan.php?utm_source=IndiaMart_BLSF&utm_medium=website&utm_campaign=IndiaMart_BL &utm_term=business+loan&utm_content=bl&gclid=";
    }

    

    if (glid) {
        if(lender_name == 'icici')
        {
            url += ("&glid=" + glid);
        }
        else if(lender_name == 'poonawalla')
        {
            url += glid;
        }

        window.open(url, '_blank');
    }
    else {
        window.open(url, '_blank');
    }
}
function getcookieready(dataarray) {
    const formattedString = Object.entries(dataarray)
        .map(([key, value]) => `${key}=${value}`)
        .join('|');

    return formattedString;
}
export class PoonawallaProductComponent extends Component {
    constructor(props){
        super(props);
        this.state = {show:false};
    }
    logincheck = (vendor_name, vendor_url) =>
    {
        var glid = getGlid();
    
        if (!glid) {
            this.setState({show:true});
            // window.open('https://poonawallafincorp.com/poonawalla-indiamart-business-loan.php?utm_source=IndiaMart_BLSF&utm_medium=website&utm_campaign=IndiaMart_BL &utm_term=business+loan&utm_content=bl&gclid=', '_blank');
            gatrack('Non Logged in User','Popup Opened - Other Webpages','Click');
        }
        else {
            gatrack('Apply for loan', vendor_name, 'Click');
            clicktracking(vendor_name);
            glidtracker('poonawalla');
        }
        
    }

  render() {
    return (
        <>
        <div className='container sec03 text-center' style={{paddingTop:'65px'}}>
            <div className='row' style={{marginLeft:'10px'}}>
                <div className='col-12' style={{textAlign:'left', paddingBottom:'48px'}}>
                    <span>Types of Products</span>
                </div>
            </div>

            <section style={{background:'#F5FAFF', paddingBottom:'50px'}} >

                <div className='row' style={{border: '1px #2E3192', borderStyle: 'none none solid none',paddingTop:'59px', textAlign:'left', paddingLeft:'40px', marginLeft:'15px', marginRight:'10px'}}>
                    <div className='col-6 productname' style={{paddingBottom:'35px' , marginLeft:'-40px'}}>
                        <strong>Unsecured Business Loans</strong>
                    </div>
                    <div className='col-6' style={{textAlign:'right'}}>
                        <button style={buttonstyle} onClick={() => this.logincheck('Poonawalla Business Loan','')}>Apply Now</button>
                    </div>
                </div>
                <div style={{ marginTop: '32px' }}>

                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="d-flex" style={{ textAlign: 'left', position: 'relative', left: '-13px' }}>
                                            <div style={expandingIconStyle}>
                                                <img src={feature1} alt="Int Rate Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Interest Rate:<br/> <strong style={{display:'flex'}}><div style={{marginRight:'3px',fontSize:'16px',position:'relative',top:'1px'}}>Starts</div> @ 15%*</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="d-flex " style={{ textAlign: 'left' }}>
                                            <div style={tenureIconStyle}>
                                                <img src={feature3} alt="Tenure Icon" style={{ paddingTop: '8px' }} />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Tenure: <br/> <strong>Upto 3 years</strong>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div className="d-flex " style={{ textAlign: 'left' }}>
                                            <div style={pfeeicon}>
                                                <img src={feature4} alt="PPfees Icon" style={{ paddingTop: '8px' }} />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px', whiteSpace: 'nowrap' }}>
                                            Processing Fee: <br/> <strong>3%*</strong>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{textAlign:'right',fontSize:'11px',position:'relative',top:'20px'}}>Note: Processing fees is applicable on sanction amount plus applicable taxes.</div>
                            <div style={{textAlign:'right',fontSize:'11px',position:'relative',top:'20px'}}>The rate of interest and processing fee is prone to fluctuation basis our internal credit and risk policy.</div>
                        </div>
                

                <div style={{marginTop:'40px', marginLeft:'40px',background: '#D5E4F2',borderRadius: '16px', paddingBottom:'32px', marginRight:'40px'}}>
                        <div style={eligcriteria}>
                            Eligibility Criteria
                        </div>

                        <div>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-4 d-flex justify-content-center">
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Age</div>
                                    <div style={featurevaltextstyle}>24 to 65 years</div>
                                </div>

                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Business Vintage</div>
                                    <div style={featurevaltextstyle}>Minimum 2 years</div>
                                </div>
                                </div>
                                <div className="col-lg-4 d-flex justify-content-center">
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Annual Turnover</div>
                                    <div style={featurevaltextstyle}>Minimum ₹6 lakh yearly</div>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>

                <div style={{marginTop:'0px'}}>
                <div style={eligcriteria}>Features and Benefits</div>
                <div style={docstextstyle}>
                 <li><strong>High Loan Amount:</strong> Up to ₹50 Lakh</li>
                 <li><strong>Fast Processing:</strong> Quick approval and disbursal</li> 
                 <li><strong>Convenient Repayment:</strong> Flexible tenure of up to 36 months</li>
                 <li><strong>No Hidden Charges:</strong> All fees and charges are disclosed</li>
                 <li><strong>Simple Application:</strong> Apply online in just a few steps</li>
                </div>    
                </div>
                <div style={{marginTop:'0px'}}>
                <div style={eligcriteria}>
                            Required Documents
                        </div>
                <div style={docstextstyle}>
                 <li><strong>KYC Documents</strong>
                    <div style={{paddingLeft:'25px'}}>
                    <li ><strong>Personal KYC Documents: </strong>PAN card, Aadhaar card, etc.</li>
                            <li ><strong>Business KYC Documents: </strong>Business License, etc.</li>
                            <li ><strong>Company KYC Documents: </strong>Director / Proprietor / Partner PAN card, Aadhaar card, etc.</li>
                            <li ><strong>Proprietor / Partner KYC Documents: </strong>PAN</li>
                    </div>
                 </li>
                 <li><strong>Business Address Proof</strong> (Utility bills / Rent agreement)</li> 
                 <li><strong>Financial Documents</strong> (Bank account statement for the past 6 months)</li>
                </div>    
                </div>
                <div style={{ textAlign: 'right', fontSize: '11px', top: '30px', position: 'relative' }}>Note: The above-mentioned Business Loan documents list is indicative. Additional documents may be required during loan processing.</div>


            </section>

            
            </div>    

            {
                    this.state.show && 
                    <ModalComponent url={'https://poonawallafincorp.com/poonawalla-indiamart-business-loan.php?utm_source=IndiaMart_BLSF&utm_medium=website&utm_campaign=IndiaMart_BL&utm_term=business+loan&utm_content=bl&gclid='} />
                }
        </>
    )
  }
}

export default PoonawallaProductComponent