import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';

import {clicktracking, gatrack, glidtracker} from '../../utils';

import indifi_desktop from '../../Images/lenderimages/indifi_desktop.jpeg'
import benefit1 from '../../Images/LenderIntroimages/benefit1.png'
import benefit2 from '../../Images/LenderIntroimages/benefit2.png'
import benefit3 from '../../Images/LenderIntroimages/benefit3.png'
import benefit4 from '../../Images/LenderIntroimages/benefit4.png'
import benefit5 from '../../Images/LenderIntroimages/benefit5.png'

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    background: '#EDF5FC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    marginLeft: '16px',
    textAlign:'left'
};

const pointerStyle = {
    color: 'rgb(46, 49, 146)',
    fontSize: '1.8em',
    marginRight: '10px',
    top: '5px',
    position: 'relative',
}

const listStyle = {
    listStyle: 'none',
    textAlign: 'left',
    fontSize: '18px',
    lineHeight: '28px'
}

export class IndifiIntroComponent extends Component {
    logincheck = (vendor_name, vendor_url) => {  
        glidtracker(vendor_name, 'Apply Now');
        gatrack('Apply for loan', vendor_name, 'Click');
        clicktracking(vendor_name);
    }

    render() {
        return (
            <>
                <div className='row' style={{ paddingTop: '32px', paddingBottom: '32px', marginLeft: '10px', marginRight: '10px', textAlign: 'center' ,marginTop:'40px'}}>
                    <div className='col-12' style={{ cursor: 'pointer' }} onClick={() => this.logincheck('Indifi Business Loan', "https://www.indifi.com/associate/indiamart?utm_source=GLID&utm_campaign=")}> <img src={indifi_desktop} alt='Lender Banner' style={{ maxWidth: '1200px', width: '97.1%' }} /> </div>
                </div>

                <div className="container sec02 text-center">
                    <div className='row'>
                        <div className='col-12' >
                            <h2 className="loanTapTitle" style={{ textAlign: 'left', marginLeft: '28px' }}><span style={{letterSpacing:'0.05rem'}}>Indifi</span></h2>
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '32px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 aboutlender' style={{ paddingRight: '58px', textAlign: 'center' }}>
                            <strong>Unlock Growth with Our Unsecured Business Loans!</strong>
                            <div style={{ marginTop: '10px',textAlign:'left' }}>
                            Indifi offers unsecured business loans to help business owners meet their business needs. With 100,000+ loans disbursed across 400+ cities, Indifi is a leading online lender. With Indifi, you can apply for a loan from your home or office through an easy online application process, and get a quick disbursal. With an online process and a personal relationship manager to help you in every step of the loan journey, you too can get timely funds like 40,000+ other businesses and take a step closer to your business growth. With Indifi business loans, you also get other benefits to help you achieve business growth:
                            <ul>
                                <li style={listStyle}><span style={{ ...pointerStyle }}>•</span>High Loan Amount: Get funds up to Rs. 50 lakhs</li>
                                <li style={listStyle}><span style={{ ...pointerStyle }}>•</span>Timely disbursal: Get the funds when you need them</li>
                                <li style={listStyle}><span style={{ ...pointerStyle }}>•</span>Attractive Interest Rates: Avail a loan with low interest rates starting @ 1.5% per month</li>
                                <li style={listStyle}><span style={{ ...pointerStyle }}>•</span>Flexible Repayment: Get repayment options best suited and convenient for you </li>
                                <li style={listStyle}><span style={{ ...pointerStyle }}>•</span>No Hidden Fees: Transparency is our priority and we believe in clear terms and no surprises</li>
                            </ul>
                            </div>
                            
                        </div>

                    </div>
                    
                    <div className='row' style={{ marginTop: '35px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 lenderheader'>
                            Benefits of selecting Indifi for Business Loan:
                        </div>
                    </div>

                    <div style={{ marginTop: '32px' }}>

            <div className="container" style={{paddingLeft:'30px'}}>
                                <div className="row">
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div style={expandingIconStyle}>
                                                <img src={benefit1} alt="Starting Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Starting a business
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div style={expandingIconStyle}>
                                                <img src={benefit2} alt="Expanding Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Expanding Operations
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div id='capitalIconStyle'>
                                                <img src={benefit3} alt="Capital Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Working Capital Requirements
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div id='assetIconStyle'>
                                                <img src={benefit4} alt="Asset Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Asset or Inventory Purchase
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '33px' }}>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div id="machineryIconStyle">
                                                <img src={benefit5} alt="Machinery Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Upgrading Machinery
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    </div>
                </div>
            </>
        )
    }
}

export default IndifiIntroComponent