import React, { Component } from 'react';

import secimage from '../../Images/knowloanimages/secimage.png'
import unsecimage from '../../Images/knowloanimages/unsecimage.png'
import amount from '../../Images/knowloanimages/amount.png'
import paperless from '../../Images/knowloanimages/paperless.png'
import collateral from '../../Images/knowloanimages/collateral.png'
import interestrate from '../../Images/knowloanimages/interestrate.png'
import loantype from '../../Images/knowloanimages/loantype.png'
import tenure from '../../Images/knowloanimages/tenure.png'
import superfast from '../../Images/knowloanimages/superfast.png'
import secstep1 from '../../Images/knowloanimages/secstep1.png'
import secstep2 from '../../Images/knowloanimages/secstep2.png'
import secstep3 from '../../Images/knowloanimages/secstep3.png'
import secstep4 from '../../Images/knowloanimages/secstep4.png'
import unsecstep1 from '../../Images/knowloanimages/unsecstep1.png'
import unsecstep2 from '../../Images/knowloanimages/unsecstep2.png'
import unsecstep3 from '../../Images/knowloanimages/unsecstep3.png'
import unsecstep4 from '../../Images/knowloanimages/unsecstep4.png'
import arrow from '../../Images/knowloanimages/Arrow.png'

const scrollToID = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const yOffset = -80; // Adjust the offset as needed
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  };

class KnowLoanComponent extends Component
{
    constructor(props) {
        super(props);
        this.state = {showsec : false, showunsec : true};
        
    }

    showKnowUnsec = () => {
        this.setState({showunsec:true});
        this.setState({showsec:false});
    }

    showKnowSec = () => {
        this.setState({showunsec:false});
        this.setState({showsec:true});
    }


    render()
    {
        return (
            <>
                <section style={{background: '#F8F9FA'}}>
                    <div className="container sec03 text-center">
                    <div className="row justify-content-center">
                        <div className="col-12">
                        <h2 className="title">Know More About <span>Your Loan</span></h2>
                        </div>
                        <div className="col-lg-4 col-sm-auto knowloan_unsec_loan" style={{marginRight: '1%',maxWidth: '315px'}}>
                        <ul>
                            <li>
                            <button id="DUBL" style={{color: this.state.showunsec ? '#091E42' : '#000', background: this.state.showunsec ? '#F1F3FE' : 'none', fontWeight: this.state.showunsec ? '700' : '400', border: this.state.showunsec ? 'none' : 'none' , borderRadius: this.state.showunsec ? '8px' : 'none' }} onClick={()=> this.showKnowUnsec()}><img
                                src={unsecimage} alt="unsecured_tabimage"/> <span className="bloan_unsec"> Collateral-free Loans
                                </span> </button>
                            </li>
                            <li>
                            <button id="DSBL" style={{color: this.state.showsec ? '#091E42' : '#000', background: this.state.showsec ? '#F1F3FE' : 'none', fontWeight: this.state.showsec ? '700' : '400', border: this.state.showsec ? 'none' : 'none' , borderRadius: this.state.showsec ? '8px' : 'none'  }} onClick={()=> this.showKnowSec()}> <img
                                src={secimage} alt="secured_tabimage"/><span className="bloan_sec"
                                style={{lineHeight: '5px !important'}}> Secured Business Loan / Loan Against Property </span></button>
                            </li>
                            
                            <li>
                            <a href="#popup1" data-bs-toggle="modal" data-bs-target="#compareloan"> Compare loans </a>
                            </li>
                        </ul>
                        </div>
                        <div className="col-lg-8 col-sm-auto loandesc">
            
                            {this.state.showunsec && 
                                <>
                                    <Knowloan_Unsec />
                                </>
                            }
                            {this.state.showsec && 
                                <>
                                    <Knowloan_Sec/>
                                </>
                            
                            }
                        </div>
                    </div>
                    </div>

                    {/* <!-- Modal --> */}
                    <div className="modal fade show" id="compareloan">
                    <div className="modal-dialog" style={{maxWidth: '1200px'}}>
                        <div className="modal-content">
                        <div className="modal-header">
                            <h1 className="modal-title fs-5">Find the <span>perfect business</span> loan for your specific needs
                            </h1>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <div className="row justify-content-md-center gx-5 text-center">
                            <div className="col-3">
                                <ul className="list-group unset">
                                <li className="list-group-item">Loan Amount</li>
                                <li className="list-group-item">Loan Type</li>
                                <li className="list-group-item">Tenure</li>
                                <li className="list-group-item">Rate of Interest</li>
                                <li className="list-group-item">Journey Type</li>
                                <li className="list-group-item">Timeline</li>
                                <li className="list-group-item">Collateral</li>
                                </ul>
                            </div>
                            <div className="col-3">
                                <ul className="list-group set">
                                <li className="list-group-item active" style={{backgroundColor: '#3336A1'}}>Collateral-free<br/> Business
                                    Loans </li>
                                <li className="list-group-item">Upto ₹20 Lakhs</li>
                                <li className="list-group-item">Term Loan</li>
                                <li className="list-group-item">Upto 5 Years</li>
                                <li className="list-group-item">18% p.a.* Onwards</li>
                                <li className="list-group-item">100% Paperless</li>
                                <li className="list-group-item">Superfast</li>
                                <li className="list-group-item">Not Required</li>
                                </ul>

                            </div>
                            <div className="col-3">
                                <ul className="list-group set">
                                <li className="list-group-item active" style={{backgroundColor: '#3336A1'}}>Secured Business Loan /<br/> Loan
                                    Against Property </li>
                                <li className="list-group-item">Upto ₹1 Crore</li>
                                <li className="list-group-item">CC/OD</li>
                                <li className="list-group-item">Not Applicable</li>
                                <li className="list-group-item">8.5% p.a.* onwards</li>
                                <li className="list-group-item">Assisted</li>
                                <li className="list-group-item">Fast</li>
                                <li className="list-group-item">Required</li>
                                </ul>
                            </div>
                            
                            </div>
                        </div>

                        </div>
                    </div>
                    </div>
                </section>

            </>
        );
    }
}

function Knowloan_Unsec()
{
    return (
        <div id="DUBL-Tab">
        <h3>Simplify tedious loan process with us <span className="btnExplore" onClick={() => scrollToID('lenders')}
            >Explore </span></h3>
        <div className="row">&nbsp;</div>
        <div className="box">
        <div className="row features">
            <h3>Features</h3>

            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${amount})`,backgroundPosition: '0 -23px'}}><span>Upto ₹20 Lakhs</span>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${paperless})`,backgroundPosition: '-32px -23px'}}><span>100%
                Paperless</span>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${superfast})`,backgroundPosition: '-32px -23px'}}><span>Superfast</span>
            </div>
            <div className="col-md-6  col-lg-3 col-sm-auto"
            style={{background: `url(${tenure})`,backgroundPosition: '-32px -23px'}}><span>Upto 5 Years</span>
            </div>

            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${interestrate})`,backgroundPosition: '-32px -23px'}}><span>18 % p.a.*
                Onwards</span></div>
            <div className="col-md-6 col-lg-5 col-sm-auto"
            style={{background:`url(${collateral})`,backgroundPosition: '-32px -23px'}}><span>No Collateral
                Required</span></div>


        </div>
        <div className="row howWorks">
            <h3>How it works ?</h3>
            <div className="col-lg-2"><img src={unsecstep1} alt="unsecured_step1_image"/>
            <div>Apply for loan & upload the documents</div>
            </div>
            <div className="col-lg-1 lgOnly"><img src={arrow} alt="arrow" style={{marginTop: '55px'}}/> </div>
            <div className="col-lg-2"><img src={unsecstep2} alt="unsecured_step2_image"/>
            <div>Get the loan approval</div>
            </div>
            <div className="col-lg-1 lgOnly"><img src={arrow} alt="arrow" style={{marginTop: '55px'}}/> </div>
            <div className="col-lg-2"><img src={unsecstep3} alt="unsecured_step3_image"/>
            <div>Complete the verification process</div>
            </div>
            <div className="col-lg-1 lgOnly"><img src={arrow} alt="arrow" style={{marginTop: '55px'}}/> </div>
            <div className="col-lg-2"><img src={unsecstep4} alt="unsecured_step4_image"/>
            <div>Get the loan disbursal</div>
            </div>
        </div>

        <div className="row eligibility" style={{textAlign: 'left'}}>
            <h3>Eligibility</h3>
            <div className="col-lg-6"><i className="bi bi-check-circle-fill" style={{color: '#49A399'}}></i><span> Age Between
                <b> 23 - 60</b> Years</span></div>
            <div className="col-lg-6"><i className="bi bi-check-circle-fill" style={{color: '#49A399'}}></i><span> Annual
                Turnover - Above <b>₹ 20 Lakhs</b> yearly</span></div>
            <div className="col-lg-6"><i className="bi bi-check-circle-fill" style={{color: '#49A399'}}></i><span> CIBIL Score
                - Above <b>700</b></span></div>
            <div className="col-lg-6"><i className="bi bi-check-circle-fill" style={{color: '#49A399'}}></i><span> Business
                Vintage - Above <b>6</b> Months</span></div>
        </div>

        <div className="row documents">
            <h3>Documents</h3>
            <div className="col-lg-6">
            <div className="row">
                <div className="col-12"><i className="bi bi-file-earmark-text-fill" style={{color: '#518ff5'}}></i> <b>KYC</b> information of Business</div>
                <div className="col-12"><i className="bi bi-file-earmark-text-fill" style={{color: '#518ff5'}}></i> 6-12 Months
                <b> Bank Statements</b>
                </div>

            </div>
            </div>
            <div className="col-lg-6 ">
            <div className="note">Please note that the above list of documents is indicative. During Loan processing
                further documents may be required. </div>
            </div>
        </div>
        </div>
    </div>
    );
}

function Knowloan_Sec()
{
    return (
        <div id="DSBL-Tab" >
        <h3>Simplify tedious loan process with us <span className="btnExplore" onClick={() => scrollToID('lenders')}
            >Explore</span></h3>
        <div className="row">&nbsp;</div>

        <div className="box">
        <div className="row features">
            <h3>Features</h3>

            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${amount})`,backgroundPosition: '0 -23px'}}><span>Upto ₹1 Crore</span>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${paperless})`,backgroundPosition: '-32px -23px'}}><span>100%
                Paperless</span> </div>
            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${superfast})`,backgroundPosition: '-32px -23px'}}><span>End to End
                Assistance</span> </div>
            

            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${loantype})`,backgroundPosition: '0 -23px'}}><span>CC/OD
                Available</span></div>
            <div className="col-md-6 col-lg-3 col-sm-auto"
            style={{background: `url(${interestrate})`,backgroundPosition: '-32px -23px'}}><span>8.5 % p.a.*
                Onwards</span></div>
            <div className="col-md-6 col-lg-5 col-sm-auto"
            style={{background: `url(${collateral})`,backgroundPosition: '-32px -23px'}}><span>Collateral
                Required</span></div>


        </div>
        <div className="row howWorks">
            <h3>How it works ?</h3>
            <div className="col-lg-2"><img src={secstep1} alt="secured_step1_image"/>
            <div>Provide your information & business documents</div>
            </div>
            <div className="col-lg-1 lgOnly"><img src={arrow} alt="arrow" style={{marginTop: '55px'}}/> </div>
            <div className="col-lg-2"><img src={secstep2} alt="secured_step2_image"/>
            <div>Get the loan approval</div>
            </div>
            <div className="col-lg-1 lgOnly"><img src={arrow} alt="arrow" style={{marginTop: '55px'}}/> </div>
            <div className="col-lg-2"><img src={secstep3} alt="secured_step3_image"/>
            <div>Sign the loan Agreement & Repayment instrument</div>
            </div>
            <div className="col-lg-1 lgOnly"><img src={arrow} alt="arrow" style={{marginTop: '55px'}}/> </div>
            <div className="col-lg-2"><img src={secstep4} alt="secured_step4_image"/>
            <div>Get the Money in your bank</div>
            </div>
        </div>

        <div className="row eligibility" style={{textAlign: 'left'}}>
            <h3>Eligibility</h3>
            <div className="col-lg-6"><i className="bi bi-check-circle-fill" style={{color: '#49A399'}}></i><span> Age Between
                <b> 23 - 60</b> Years</span></div>
            <div className="col-lg-6"><i className="bi bi-check-circle-fill" style={{color: '#49A399'}}></i><span> Annual
                Turnover - Min <b>Rs 50 Lakhs</b> Yearly</span></div>
            <div className="col-lg-6"><i className="bi bi-check-circle-fill" style={{color: '#49A399'}}></i><span> CIBIL Score
                - Above <b>700</b></span></div>
            <div className="col-lg-6"><i className="bi bi-check-circle-fill" style={{color: '#49A399'}}></i><span> Business
                Vintage - Above <b>6</b> Months</span></div>
        </div>

        <div className="row documents">
            <h3>Documents</h3>
            <div className="col-lg-6">
            <div className="row">
                <div className="col-12"><i className="bi bi-file-earmark-text-fill" style={{color: '#518ff5'}}></i> <b>KYC</b> information of Business</div>
                <div className="col-12"><i className="bi bi-file-earmark-text-fill" style={{color: '#518ff5'}}></i> 6-12 Months
                <b> Bank Statements</b>
                </div>
                <div className="col-12"><i className="bi bi-file-earmark-text-fill" style={{color: '#518ff5'}}></i> Min. 2 year ITR Statements</div>
                <div className="col-12"><i className="bi bi-file-earmark-text-fill" style={{color: '#518ff5'}}></i> Proof of
                Business Ownership</div>
                <div className="col-12"><i className="bi bi-file-earmark-text-fill" style={{color: '#518ff5'}}></i> Documents
                of the Property to be Mortgaged</div>
                <div className="col-12"><i className="bi bi-file-earmark-text-fill" style={{color: '#518ff5'}}></i> GST Details
                of Business</div>

            </div>
            </div>
            <div className="col-lg-6">
            <div className="note">Please note that the above list of documents is indicative. During Loan processing
                further documents may be required. </div>
            </div>
        </div>
        </div>
    </div>
    );
    
}

export default KnowLoanComponent;