import React, { Component } from 'react'

import ProductsPageBody from '../body/ProductsPageBody';
import HeaderComponent from '../header/HeaderComponent';
import FooterComponent from '../footer/FooterComponent'
export class ProductsPage extends Component {
  render() {
    return (
      <>
      <HeaderComponent/>
      <ProductsPageBody/>
      <FooterComponent/>
      </>
    )
  }
}

export default ProductsPage;