import React, { Component, createRef } from 'react'

import HeaderComponent from '../header/HeaderComponent';
import CurrentApplicationsComponent from '../applicationComponents/CurrentApplicationsComponent';
import ExploreComponent from '../applicationComponents/ExploreComponent';
import FooterComponent from '../footer/FooterComponent'; 
import TestimonialsComponent from '../testimonials/TestimonialsComponent';
//import { LoansHelp } from '../educationPopup/LoansHelp';
import { getGlidEnd } from '../../utils';
const glidEnd = getGlidEnd();
export class ApplicationsPage extends Component {

  constructor(props) {
    super(props);
    this.currentAppRef = createRef();
  }

  // scrollToCurrentApp = () => {
  //   if (this.currentAppRef.current) {
  //     this.currentAppRef.current.scrollIntoView({ behavior: 'smooth' });
  //   }
  // };

  render() {
    return (
      <>
      <HeaderComponent/>
      <CurrentApplicationsComponent ref = {this.currentAppRef}/>
      <ExploreComponent/>
      <TestimonialsComponent/>
      <FooterComponent/>
      {/* {(glidEnd == '0' || glidEnd == '1' || glidEnd == '2' || glidEnd == '3' || glidEnd == '4') && <LoansHelp scrollTo = {this.scrollToCurrentApp} callerComp = 'Applications'/>} */}
      </>
    )
  }
}

export default ApplicationsPage