import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';

import {getGlid, clicktracking, gatrack, glidtracker} from '../../utils';

import lenderbanner from '../../Images/lenderimages/TataCapitalBanner.png'
import advantage1 from '../../Images/tataCapitalIcons/Customized-Loan-Amount.png'
import advantage2 from '../../Images/tataCapitalIcons/Flexible-Loan-Tenure.png'
import advantage3 from '../../Images/tataCapitalIcons/Term-Loan-or-OD.png'
import advantage4 from '../../Images/tataCapitalIcons/Minimum-Documentation.png'
import advantage5 from '../../Images/tataCapitalIcons/Quick-Approval-and-Fast-Disbursal.png'
import advantage6 from '../../Images/tataCapitalIcons/No-Collateral-Required.png'
import advantage7 from '../../Images/tataCapitalIcons/Doorstep-Services.png'
import benefit1 from '../../Images/tataCapitalIcons/Expansion-of-Your-Business.png'
import benefit2 from '../../Images/tataCapitalIcons/Purchase-Raw-Materials.png'
import benefit3 from '../../Images/tataCapitalIcons/Shortage-of-Cash-flow.png'
import benefit4 from '../../Images/tataCapitalIcons/Buy-the-Latest-Equipment.png'

import ModalComponent from '../common/ModalComponent';

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    marginLeft: '16px',
    textAlign: 'left'
};

class TataCapitalIntroComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { show: false, url_selector: "Apply Now" };
    }

    logincheck = (vendor_name, vendor_url, url_selector) => {
        var glid = getGlid();

        if (!glid) {
            console.log("GLID not recieved.")
            this.setState({ show: true });
        }
        else {
            glidtracker(vendor_name, url_selector);
            gatrack('Apply for loan', vendor_name, 'Click');
            clicktracking(vendor_name);
        }

    }

    checkApplication(glid, lender){
        var host = window.location.hostname;
        if (host == 'loans.indiamart.com') {
            var checkAppURL = "https://loans.indiamart.com/functions.php";
        }
        else {
            var checkAppURL = "https://dev-loans.indiamart.com/functions.php";
        }

        var checkAppData = {
            "glid": glid,
            "lender":lender,
            "functionName": "checkApplication",
            // "respFlag":'1'
        };

        var formData = new FormData();
        for (var key in checkAppData) {
            formData.append(key, checkAppData[key]);
        }

        var self = this;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: checkAppURL,
            data: formData,
            timeout: 10000
        }).then((response) => {
            if (response.data === true) {
                this.setState({ url_selector: "Continue" });
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    componentDidMount(){
        var glid = getGlid();
        this.checkApplication(glid, 'tata capital');
    }

    toggleModal = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
    }


    render() {
        return (
            <>
                <div className='row' style={{ paddingTop: '32px', paddingBottom: '32px', marginLeft: '10px', marginRight: '10px', textAlign: 'center' ,marginTop:'40px'}}>
                    <div className='col-12' style={{ cursor: 'pointer' }} onClick={() => this.logincheck('Tata Capital Business Loan', 'https://businessloan.tatacapital.com/online/loans/business-loans/home?sourceName=INDIAMART', this.state.url_selector)} > <img src={lenderbanner} alt='Lender Banner' style={{ maxWidth: '1200px', width: '97.1%' }} /> </div>
                </div>

                <div className="container sec02 text-center">
                    <div className='row'>
                        <div className='col-12' >
                            <h2 className="creditenableTitle" style={{ textAlign: 'left', marginLeft: '28px' }}><span>Tata Capital – Your Trusted Business Growth Partner</span></h2>
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '32px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 aboutlender' style={{ paddingRight: '58px', textAlign: 'justify' }}>
                            Discover the endless possibilities for your business growth with Tata Capital, the leading financial services company of the prestigious Tata Group. We are proud to offer a wide range of products and services as a subsidiary of Tata Sons Private Limited.<br />
                            Tata Capital and its subsidiaries offer a wide range of financial services, including commercial finance, consumer loans, wealth services and Tata credit cards. Opt for Tata Capital to experience smooth and hassle-free financial solutions, and give your business aspirations a boost.
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '35px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 lenderheader'>
                            Business Loans By Tata Capital:
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '32px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 aboutlender' style={{ paddingRight: '58px', textAlign: 'justify' }}>
                            Every business, irrespective of its size and nature, requires funding from time to time. A business loan is an unsecured credit that can help you meet your growing business’ urgent financial requirements. You can apply for a business loan to usher in the funds required to expand your existing business, boost its production, take your business online, procure inventory, buy equipment, and much more.<br />
                            You can apply for a business loan with us and get business finance of up to Rs. 90 lakhs. You can avail of our online business loan from the convenience of your home or office. We won’t make you run from pillar to post or burden you with endless paperwork. Additionally, you can choose a suitable tenure to repay your business loan amount at your convenience.
                        </div>
                    </div>

                    <div style={{ fontSize: '14px', lineHeight: '13px', marginLeft: '32px', marginTop: '15px', display: 'flex' }}><span style={{ fontWeight: 'bold' }}>Disclaimer:</span><span style={{ textAlign: 'left', marginLeft: '5px' }}> Business Loans are at the sole discretion of Tata Capital Limited (TCL). Terms and conditions apply.<br /></span></div>

                    <div className='row' style={{ marginTop: '15px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 aboutlender' style={{ paddingRight: '58px', textAlign: 'justify' }}>
                            <ul style={{ paddingLeft: '15px' }}>
                                {/* <li>Loan Amount: ₹ 40000 to ₹ 90 lakhs</li>
                    <li>Loan Tenure: 12 to 60 months</li> */}
                                {/* <li>Self-Employed Non-Professional Interest rate starting @ 15% - 26%</li> */}
                                {/* <li>Self-Employed Doctors Interest rate starting @12.5% - 13.75%</li> */}
                                {/* <li>Self-Employed Professional Other than Doctors Interest rate starting @ 13.5% - 17.50%</li> */}
                            </ul>
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '30px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 lenderheader'>
                            Advantages of Tata Capital Business Loan?
                        </div>
                    </div>
                    <div style={{ marginTop: '32px' }}>

                        <div className="container" style={{ paddingLeft: '30px' }}>
                            <div className="row">
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={advantage1} alt="Starting Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Customized Loan Amount
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={advantage2} alt="Expanding Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Flexible Loan Tenure
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={advantage3} alt="Capital Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Term Loan or OD
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={advantage4} alt="Asset Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Minimum Documentation
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ marginTop: '20px' }}>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={advantage5} alt="Starting Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Quick Approval and Fast Disbursal
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={advantage6} alt="Expanding Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            No Collateral Required
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={advantage7} alt="Capital Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Doorstep Services
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='row' style={{ marginTop: '35px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 lenderheader'>
                            Benefits of selecting Tata Capital for Business Loan:
                        </div>
                    </div>

                    <div style={{ marginTop: '32px' }}>

                        <div className="container" style={{ paddingLeft: '30px' }}>
                            <div className="row">
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={benefit1} alt="Starting Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Expansion of Your Business
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={benefit2} alt="Expanding Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Purchase Raw Materials
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={benefit3} alt="Capital Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Shortage of Cash-flow
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 d-flex align-items-center">
                                    <div className="d-flex align-items-center">
                                        <div>
                                            <img src={benefit4} alt="Asset Icon" style={expandingIconStyle} />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle }}>
                                            Buy the Latest Equipment
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                {
                    this.state.show &&
                    <ModalComponent url={"https://www.tatacapital.com/online/loans/business-loans/home?sourceName=INDIAMART&PartnerID="} lender_name = {'tata capital'} vendor_name = {'Tata Capital Business Loan'} handleClose={this.toggleModal}/>
                }
            </>
        )
    }
}

export default TataCapitalIntroComponent