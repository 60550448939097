import React, { Component } from 'react'

import banner from '../../Images/aboutUsImages/aboutUsMainBanner.png'
import betterPricingImage from '../../Images/aboutUsImages/betterPricingImage.svg'
import paperlessImage from '../../Images/aboutUsImages/paperlessImage.svg'
import approvalImage from '../../Images/aboutUsImages/approvalImage.svg'
import guidanceImage from '../../Images/aboutUsImages/guidanceImage.svg'
import coverageImage from '../../Images/aboutUsImages/coverageImage.svg'


const aboutIndiamartStyle = {
    fontFamily: 'Arial',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '100%', // Adjusted to match the provided line-height
    textTransform: 'capitalize',
    background: '-webkit-linear-gradient(91deg, #1A54AB 40.45%, #28B6BF 103.91%)',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent'// Adjust as needed
}

const paraStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    marginRight:'50px'
}

const benefitsHeadingStyle = {
    color: '#1C58AD',
    textAlign: 'left',
    fontFamily: 'Arial',
    fontSize: '21px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
    marginTop: '40px'
        
    
}

export class AboutUSPageBody extends Component {
    render() {
        return (
            <>
                <section>
                    <div className='row' style={{ marginTop: '52px', marginLeft: '48px', marginRight: '48px' }}>
                        <div className='col-12' style={{textAlign:'center'}}><img src={banner} style={{width:'85%',height:'100%'}}/></div>
                        <div style={{ marginTop: '52px', marginLeft: '66px', marginRight: '67px' }}>
                            <div style={aboutIndiamartStyle}>About IndiaMART Loans</div>
                            <div style={{ ...paraStyle, marginTop: '20px' }}>Welcome to the one stop solution for all your Business Loan requirements. We provide Term Loan and Overdraft facility under Collateral free Business Loans and Loan Against Property under Secured Business Loans. At IndiaMART, we are committed to scale your business growth by providing you the most suitable business loan options at the most affordable rates.</div>
                            <div style={benefitsHeadingStyle}>Following are the benefits to consider the business loan financing from us:</div>
                        </div>
                    </div>
                    <div style={{ marginTop: '50px', marginLeft: '163px', marginRight: '162px' }}>
                        <div className="container" style={{padding:'0px'}}>
                            <div className="row">
                                <div className="col-4" style={{paddingLeft:'20px', paddingRight:'20px'}}>

                                    <img src={betterPricingImage} />
                                </div>
                                <div className="col-4" style={{paddingLeft:'20px', paddingRight:'20px'}}>

                                    <img src={paperlessImage} />
                                </div>
                                <div className="col-4" style={{paddingLeft:'20px', paddingRight:'20px'}}>

                                    <img src={approvalImage} />
                                </div>
                            </div>
                            <div className="row" style={{marginTop:'24px'}}>
                                <div className="col-6" style={{paddingRight:'10px'}}>

                                    <img src={guidanceImage} style={{float:'right'}}/>
                                </div>
                                <div className="col-6" style={{paddingLeft:'10px'}}>

                                    <img src={coverageImage} />
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default AboutUSPageBody