import React, { Component } from 'react'

import HeaderComponent from '../header/HeaderComponent'
import TataCapitalIntroComponent from '../lenderintro/TataCapitalIntroComponent'
import TataCapitalProductComponent from '../products/TataCapitalProductComponent'
import TataCapitalFAQsComponent from '../lenderFAQs/TataCapitalFAQsComponent'
import FooterComponent from '../footer/FooterComponent'

export class TataCapitalPage extends Component {
  render() {
    return (
      <>
        <HeaderComponent/>
        <TataCapitalIntroComponent/>
        <TataCapitalProductComponent/>
        <TataCapitalFAQsComponent/>
        <FooterComponent/>
      </>
    )
  }
}

export default TataCapitalPage