import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';

// import feature1 from '../../Images/productsimages/feature1.png'
// import feature2 from '../../Images/productsimages/feature2.png'
// import feature3 from '../../Images/productsimages/feature3.png'
// import feature4 from '../../Images/productsimages/feature4.png'
import {getGlid, clicktracking, gatrack, glidtracker} from '../../utils';

import loanAmountIcon from '../../Images/tataCapitalIcons/Customized-Loan-Amount.png'
import tenureIcon from '../../Images/tataCapitalIcons/Tenure.png'
import feature1 from '../../Images/productsimages/feature1.png'
import feature4 from '../../Images/productsimages/feature4.png'

import ModalComponent from '../common/ModalComponent';

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    background: '#EDF5FC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px'
};

const buttonstyle = {
    width: '199px',
    height: '54px',
    flexShrink: '0',
    borderRadius: '10.595px',
    background: 'var(--Brand1, #2E3192)',
    borderStyle: 'none',
    color: '#FFF',
    fontFamily: 'Arial',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    marginTop : '-10px'
};

const emiIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#FCF1ED',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const tenureIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#FCF9ED',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const pfeeicon = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#ECECEC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const eligcriteria = {
    paddingTop:'35px',
    textTransform: 'capitalize',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '100%',
    textAlign:'left',
    paddingLeft:'32px',
    paddingBottom:'24px' 
}

const featuretextstyle = {
    marginBottom: '10px',
    color: '#626262',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '147.023%' 
}

const featurevaltextstyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '147.023%'
}

const docstextstyle = {
    textAlign:'left', 
    marginLeft:'40px',
    textIndent:'-25px',
    paddingLeft:'25px',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '186%' /* 33.48px */
}

export class TataCapitalProductComponent extends Component {
    constructor(props) {
        super(props);
        this.state = { show: false, url_selector: "Apply Now" };
    }

    logincheck = (vendor_name, vendor_url, url_selector) => {
        var glid = getGlid();

        if (!glid) {
            this.setState({ show: true });
        }
        else {
            glidtracker(vendor_name, url_selector);
            gatrack('Apply for loan', vendor_name, 'Click');
            clicktracking(vendor_name);
        }

    }

    checkApplication(glid, lender){
        var host = window.location.hostname;
        if (host == 'loans.indiamart.com') {
            var checkAppURL = "https://loans.indiamart.com/functions.php";
        }
        else {
            var checkAppURL = "https://dev-loans.indiamart.com/functions.php";
        }

        var checkAppData = {
            "glid": glid,
            "lender":lender,
            "functionName": "checkApplication",
            // "respFlag":'1'
        };

        var formData = new FormData();
        for (var key in checkAppData) {
            formData.append(key, checkAppData[key]);
        }

        var self = this;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: checkAppURL,
            data: formData,
            timeout: 10000
        }).then((response) => {
            if (response.data === true) {
                this.setState({ url_selector: "Continue" });
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    componentDidMount(){
        var glid = getGlid();
        this.checkApplication(glid, 'tata capital');
    }

    toggleModal = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
    }
  render() {
    return (
        <>
        <div className='container sec07 text-center' style={{paddingTop:'65px'}}>
        <div className='row' style={{marginLeft:'10px'}}>
                    <div className='col-12' style={{textAlign:'left', paddingBottom:'48px'}}>
                        <span>Product Offered</span>
                    </div>
                </div>

            <section style={{background:'#F5FAFF', paddingBottom:'35px'}} >

                <div className='row' style={{border: '1px #2E3192', borderStyle: 'none none solid none',paddingTop:'59px', textAlign:'left', paddingLeft:'40px', marginLeft:'15px', marginRight:'10px'}}>
                    <div className='col-6 productname' style={{paddingBottom:'35px' , marginLeft:'-40px'}}>
                    <strong>Unsecured Business Loans</strong>
                    </div>
                    <div className='col-6' style={{textAlign:'right'}}>
                        <button style={buttonstyle} onClick={() => this.logincheck('Tata Capital Business Loan', 'https://businessloan.tatacapital.com/online/loans/business-loans/home?sourceName=INDIAMART', this.state.url_selector)}>Apply Now</button>
                    </div>
                </div>
                <div style={{ marginTop: '32px' }}>

                        <div className="container" style={{paddingLeft:'30px'}}>
                            <div className="row">
                                <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className="d-flex" style={{ textAlign: 'left', position: 'relative', left: '-13px' }}>
                                        <div>
                                            <img src={loanAmountIcon} alt="Int Rate Icon" style={expandingIconStyle}/>
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Loan Amount:<br/> <strong style={{display:'flex'}}>Upto ₹ 90 Lakhs</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className="d-flex" style={{ textAlign: 'left' }}>
                                        <div style={expandingIconStyle}>
                                            <img src={feature1} alt="Int Rate Icon" />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Interest Rate:<br/> <strong style={{display:'flex'}}><div style={{marginRight:'3px',fontSize:'16px',position:'relative',top:'1px'}}>Starts</div> @ 13.5%</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                    <div className="d-flex " style={{textAlign:'left'}}>
                                        <div>
                                            <img src={tenureIcon} alt="Tenure Icon" style={{...expandingIconStyle, paddingTop:'8px'}}/>
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Tenure: <br/> <strong>Min. 1 year</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display:'grid', justifyItems:'center', marginTop:'10px'}}>
                                <div style={{ width: '73%', position: 'relative', left: '107px', fontSize: '14px' }}>Self-Employed Non-Professional Interest rate starting @ 15% - 26%</div>
                                <div style={{ width: '68%%', position: 'relative', left: '169px', fontSize: '14px' }}>Self-Employed Professional Other than Doctors Interest rate starting @ 13.5% - 17.50%</div>
                            </div>
                        </div>
                    </div>

                <div style={{marginTop:'40px', marginLeft:'40px',background: '#D5E4F2',borderRadius: '16px', paddingBottom:'32px', marginRight:'40px'}}>
                        <div style={eligcriteria}>
                            Eligibility Criteria
                        </div>

                        <div>
                        <div className="container" style={{paddingLeft:'30px'}}>
                            <div className="row">
                                <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="d-flex flex-column" style={{ textAlign: 'left', position: 'relative', left: '-13px' }}>
                                    <div style={featuretextstyle}>Age</div>
                                    <div style={featurevaltextstyle}>Minimum 21 years</div>
                                </div>

                                </div>
                                <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Business Vintage</div>
                                    <div style={featurevaltextstyle}>Minimum 2 years</div>
                                </div>
                                </div>
                                <div className="col-lg-4" style={{ display: 'flex', justifyContent: 'center' }}>
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Cibil Score</div>
                                    <div style={featurevaltextstyle}>650 & above</div>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>

                <div style={{marginTop:'0px'}}>
                <div style={eligcriteria}>
                            Required Documents
                        </div>
                <div style={docstextstyle}>
                 <li>KYC documents, including a photo ID and address proof.</li>
                 <li>Business proof, including business registration certificate, GST registration certificate, trade licence, or a certified copy of the partnership deed agreement or sole proprietorship declaration.</li> 
                 <li>Income proof, such as the last two years income tax returns (ITRs) and computation of income, the last two years audited finances with the tax audit report, the last two years profit-loss statements, or balance sheet (duly audited by a chartered accountant).</li>
                 <li>Current account bank statements and GST returns for the last six months.</li>
                 <div style={{textIndent:'0px', fontSize:'17px', fontStyle:'italic'}}>You may also require some additional documents at the time of loan processing.</div>
                </div>    
                </div>


            </section>

            
            </div>  

        {
            this.state.show &&
            <ModalComponent url={"https://www.tatacapital.com/online/loans/business-loans/home?sourceName=INDIAMART&PartnerID="} lender_name = {'tata capital'} vendor_name = {'Tata Capital Business Loan'} handleClose={this.toggleModal}/>
        }  
        </>
    )
  }
}

export default TataCapitalProductComponent