import React, { Component } from 'react'

import downwardArrowImage from '../../Images/lenderimages/faqDownArrow.svg';
import rightArrowImage from '../../Images/lenderimages/faqRightArrow.svg';

const faqHeadingStyle = {
    background: 'linear-gradient(91deg, #1A54AB 0%, #28B6BF 103.91%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
    fontFamily: 'Arial',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '32px',
    textTransform: 'capitalize',
}

const faqQuestionStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '49px',
    marginRight:'20px',
    textAlign:'justify',
}

const borderLine={
    borderTop: '1px solid rgba(9, 30, 66, 0.70)',
    height: '1px',
    opacity: '0.5',
}

const faqClickableDiv={
    cursor: 'pointer', 
    display: 'flex', 
    alignItems: 'center' 
}

const answerListStyle={
    marginRight:'40px',
    textAlign:'justify',
    color: '#091E42',
  fontFamily: 'Arial',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '30px',
}

const showMoreStyle = {
    color: 'var(--Brand1, #2E3192)',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
    textDecorationLine: 'underline',
    cursor:'pointer',
  };

export class IIFLFAQsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,
            showAnswer5: false,
            showAnswer6: false,
            showAnswer7: false,
            showMore: false,
        };
    }

    handleShowMoreClick = () => {
        this.setState((prevState) => ({
            showMore: !prevState.showMore,
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,
            showAnswer5: false,
            showAnswer6: false,
            showAnswer7: false,
          }));
          this.resetStates = this.resetStates.bind(this);
      };

      resetStates() {
        this.setState({
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,
            showAnswer5: false,
            showAnswer6: false,
            showAnswer7: false,
        });
    }

    handleClick = (number) => {
        this.resetStates();
        if(number === '1'){
            this.setState({showAnswer1:!this.state.showAnswer1});
        }
        else if(number === '2'){
            this.setState({showAnswer2:!this.state.showAnswer2});
        }
        else if(number === '3'){
            this.setState({showAnswer3:!this.state.showAnswer3});
        }
        else if(number === '4'){
            this.setState({showAnswer4:!this.state.showAnswer4});
        }
        else if(number === '5'){
            this.setState({showAnswer5:!this.state.showAnswer5});
        }
        else if(number === '6'){
            this.setState({showAnswer6:!this.state.showAnswer6});
        }
        else if(number === '7'){
            this.setState({showAnswer7:!this.state.showAnswer7});
        }
    }

    render() {
        return (
            <>
                <section>
                    <div style={{ margin:'100px' }}>
                        <div style={{ ...faqHeadingStyle }}>Frequently Asked Questions</div>
                        <div className='container' style={{ padding: '0px', marginTop: '32px' }}>
                        <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('1')} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle}}>What types of loan does IIFL offer?</div>
                                            {this.state.showAnswer1 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer1 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>IIFL offers personal loans, home loans, gold loans, business loans, mortgage loans, loans against property, and credit cards.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('2')} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle}}>What is the interest rate on IIFL loans?</div>
                                            {this.state.showAnswer2 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer2 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Interest rates vary by loan type and applicant profile, starting from 1.5% pm. Check with IIFL for the latest rates</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('3')} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle}}>What is the maximum loan amount I can get from IIFL?</div>
                                            {this.state.showAnswer3 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer3 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Maximum loan amounts range from ₹40,000 to ₹30,00,000 depending on the loan type, purpose, and applicant eligibility.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('4')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>How long does it take to get loan approval from IIFL?</div>
                                            {this.state.showAnswer4 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer4 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Most IIFL loans can be approved within 2-7 working days if you meet the eligibility criteria and provide the required documents</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('5')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>What documents do I need for an IIFL Business loan? </div>
                                            {this.state.showAnswer5 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer5 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Documents like a selfie of the accountant, Current account/savings account bank statement, ID proof, address proof, etc., are required. Specific document needs depend on the loan type.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div style={showMoreStyle} onClick={this.handleShowMoreClick}>{this.state.showMore ? 'Show Less' : 'Show More'}</div>
                            </div>
                            
                            {this.state.showMore && (
                            <>
                            <div className='row' style={{ marginTop: '0px' }}>
                                <div className='col-12'>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('6')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>Can I apply for an IIFL loan with a low or no credit score?</div>
                                            {this.state.showAnswer6 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer6 &&(
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>No, IIFL does not offer loans without higher credit score requirements.</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('7')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>Do You Need Collateral When Applying for IIFL Loan?
                                            </div>
                                            {this.state.showAnswer7 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer7 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>No, it is not compulsory to show them the collateral.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                </div>
                            </div>
                            </>)}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default IIFLFAQsComponent