import React, { Component } from 'react';
import RouterComponent from '../router/RouterComponent';
import axios from 'axios';
import Cookies from 'universal-cookie';

const setAuthorizationHeader = async () => {
    const cookie = new Cookies();

    let glid = '';
    let visitorData = cookie.get('ImeshVisitor');
    if (visitorData) {
        visitorData = visitorData.split("|");
        visitorData.forEach((data) => { 
            const sd = data.split("=");
            if (sd[0] === "glid") {
                glid = sd[1];
            }
        });
    }
    
    axios.defaults.headers.common['Authorization'] = 'Basic cGF5d2l0aGluZGlhbWFydA==';
    console.log("header auth set =>", axios.defaults.headers.common['Authorization'])
    
    const host = window.location.hostname;
    const firstauthcheckURL = host === 'loans.indiamart.com'
        ? "https://loans.indiamart.com/functions.php"
        : "https://dev-loans.indiamart.com/functions.php";
    
    const firstauthcheckPostData = {
        "functionName": "firstauthcheck",
        "glid": glid
    };
    
    const formData = new FormData();
    for (const key in firstauthcheckPostData) {
        formData.append(key, firstauthcheckPostData[key]);
    }
    
    try {
        const response = await axios.post(firstauthcheckURL, formData, {
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            timeout: 10000,
        });
    
        const data_obj = response.data;
        const encrypted = data_obj.encryptedString;
        // console.log(encrypted);
    
        axios.defaults.headers.common['Authorization'] = encrypted;
    } catch (error) {
        console.error('Error during first auth check:', error);
    }
};

class RootComponent extends Component {
    state = {
        isAuthHeaderSet: false,
    };

    async componentDidMount() {
        await setAuthorizationHeader();
        this.setState({ isAuthHeaderSet: true });
    }

    render() {
        const { isAuthHeaderSet } = this.state;
        if (!isAuthHeaderSet) {
            return <div></div>;
        }

        return <RouterComponent />;
    }
}

export default RootComponent;
