import React, { Component } from 'react'

import HeaderComponent from '../header/HeaderComponent'
import FooterComponent from '../footer/FooterComponent'
import IndifiIntroComponent from '../lenderintro/IndifiIntroComponent'
import IndifiProductComponent from '../products/IndifiProductComponent'
export class IndifiLenderPage extends Component {
  render() {
    return (
      <>
        <HeaderComponent />
        <IndifiIntroComponent/>
        <IndifiProductComponent/>
        <FooterComponent />
      </>
    )
  }
}

export default IndifiLenderPage