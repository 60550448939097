import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';

import downwardArrowImage from '../../Images/lenderimages/faqDownArrow.svg';
import rightArrowImage from '../../Images/lenderimages/faqRightArrow.svg';

const faqHeadingStyle = {
    background: 'linear-gradient(91deg, #1A54AB 0%, #28B6BF 103.91%)',
  backgroundClip: 'text',
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
    fontFamily: 'Arial',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '32px',
    textTransform: 'capitalize',
}

const faqQuestionStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '49px',
    marginRight:'20px',
    textAlign:'justify',
}

const borderLine={
    borderTop: '1px solid rgba(9, 30, 66, 0.70)',
    height: '1px',
    opacity: '0.5',
}

const faqClickableDiv={
    cursor: 'pointer', 
    display: 'flex', 
    alignItems: 'center' 
}

const answerListStyle={
    marginRight:'40px',
    textAlign:'justify',
    color: '#091E42',
  fontFamily: 'Arial',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '30px',
}

const answerListStyle2={
    marginRight:'40px',
    textAlign:'justify',
    color: '#091E42',
  fontFamily: 'Arial',
  fontSize: '17px',
  fontStyle: 'normal',
  fontWeight: '400',
  lineHeight: '30px',
  listStyleType: 'circle'
}

const showMoreStyle = {
    color: 'var(--Brand1, #2E3192)',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '24px',
    textDecorationLine: 'underline',
    cursor:'pointer',
  };

  function clicktracking(vendor_name) {

    const cookie = new Cookies();

    var glid = '';

    let visitorData = cookie.get('ImeshVisitor');
    if (visitorData) {
        visitorData = visitorData.split("|");

        let sd = [];
        visitorData.map((data) => {
            sd = data.split("=")
            if (sd[0] == "glid") {
                glid = sd[1]
            }
        })
    }

    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    var clickData = {
        "glid": glid,
        "banner_type": vendor_name
    };

    var formData = new FormData();
    for (var key in clickData) {
        formData.append(key, clickData[key]);
    }

    if (glid) {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout: 10000
        }).then(function (response) {
        })
    }

}

function gatrack(category, action, label, value = null) {

    const cookie = new Cookies();

    var glid ='';

    let visitorData = cookie.get('ImeshVisitor');
    if(visitorData)
    {
        visitorData = visitorData.split("|");

        let sd = [];
        visitorData.map((data) => { 
            sd = data.split("=")
            if(sd[0] == "glid"){
                glid = sd[1]
            }
        })
    }

    if (value) {
        window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label, 'eventValue': value });
    }
    else {
        window.imgtm.push({ 'event': 'IMEvent', 'eventCategory': category, 'eventAction': action, 'eventLabel': label, 'eventValue': glid });
    }
}

export class TataCapitalFAQsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,
            showAnswer5: false,
            showAnswer6: false,
            showAnswer7: false,
            showAnswer8: false,
            showAnswer9: false,
            showAnswer10: false,
            showAnswer11: false,
            showAnswer12: false,
            showAnswer13: false,
            showAnswer14: false,
            showAnswer15: false,
            showAnswer16: false,
            showAnswer17: false,
            showAnswer18: false,
            showAnswer19: false,
            showAnswer20: false,
            showAnswer21: false,
            showAnswer22: false,
            showMore: false,
        };
        this.resetStates = this.resetStates.bind(this);
    }

    resetStates() {
        this.setState({
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,
            showAnswer5: false,
            showAnswer6: false,
            showAnswer7: false,
            showAnswer8: false,
            showAnswer9: false,
            showAnswer10: false,
            showAnswer11: false,
            showAnswer12: false,
            showAnswer13: false,
            showAnswer14: false,
            showAnswer15: false,
            showAnswer16: false,
            showAnswer17: false,
            showAnswer18: false,
            showAnswer19: false,
            showAnswer20: false,
            showAnswer21: false,
            showAnswer22: false,
        });
    }

    handleShowMoreClick = () => {
        this.setState((prevState) => ({
            showMore: !prevState.showMore,
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,
            showAnswer5: false,
            showAnswer6: false,
            showAnswer7: false,
            showAnswer8: false,
            showAnswer9: false,
            showAnswer10: false,
            showAnswer11: false,
            showAnswer12: false,
            showAnswer13: false,
            showAnswer14: false,
            showAnswer15: false,
            showAnswer16: false,
            showAnswer17: false,
            showAnswer18: false,
            showAnswer19: false,
            showAnswer20: false,
            showAnswer21: false,
            showAnswer22: false,
          }));
    };

    handleClick = (number) => {
        this.resetStates();
        if(number === '1'){
            this.setState({showAnswer1:!this.state.showAnswer1});
        }
        else if(number === '2'){
            this.setState({showAnswer2:!this.state.showAnswer2});
        }
        else if(number === '3'){
            this.setState({showAnswer3:!this.state.showAnswer3});
        }
        else if(number === '4'){
            this.setState({showAnswer4:!this.state.showAnswer4});
        }
        else if(number === '5'){
            this.setState({showAnswer5:!this.state.showAnswer5});
        }
        else if(number === '6'){
            this.setState({showAnswer6:!this.state.showAnswer6});
        }
        else if(number === '7'){
            this.setState({showAnswer7:!this.state.showAnswer7});
        }
        else if(number === '8'){
            this.setState({showAnswer8:!this.state.showAnswer8});
        }
        else if(number === '9'){
            this.setState({showAnswer9:!this.state.showAnswer9});
        }
        else if(number === '10'){
            this.setState({showAnswer10:!this.state.showAnswer10});
        }
        else if(number === '11'){
            this.setState({showAnswer11:!this.state.showAnswer11});
        }
        else if(number === '12'){
            this.setState({showAnswer12:!this.state.showAnswer12});
        }
        else if(number === '13'){
            this.setState({showAnswer13:!this.state.showAnswer13});
        }
        else if(number === '14'){
            this.setState({showAnswer14:!this.state.showAnswer14});
        }
        else if(number === '15'){
            this.setState({showAnswer15:!this.state.showAnswer15});
        }
        else if(number === '16'){
            this.setState({showAnswer16:!this.state.showAnswer16});
        }
        else if(number === '17'){
            this.setState({showAnswer17:!this.state.showAnswer17});
        }
        else if(number === '18'){
            this.setState({showAnswer18:!this.state.showAnswer18});
        }
        else if(number === '19'){
            this.setState({showAnswer19:!this.state.showAnswer19});
        }
        else if(number === '20'){
            this.setState({showAnswer20:!this.state.showAnswer20});
        }
        else if(number === '21'){
            this.setState({showAnswer21:!this.state.showAnswer21});
        }
        else if(number === '22'){
            this.setState({showAnswer22:!this.state.showAnswer22});
        }

    }
    

    logincheck = (vendor_name, vendor_url) =>
    {
        const cookie = new Cookies();

        var glid='';

        let visitorData = cookie.get('ImeshVisitor');
        if(visitorData)
        {
            visitorData = visitorData.split("|");
    
            let sd = [];
            visitorData.map((data) => { 
                sd = data.split("=")
                if(sd[0] == "glid"){
                    glid = sd[1]
                }
            })
        }
    

        if(!glid)
        {
            window.open(vendor_url,'_blank');
        }
        else
        {
            gatrack('Apply for loan',vendor_name,'Click');
            clicktracking(vendor_name);
            window.open(vendor_url,'_blank');
        }
        
    }

    render() {
        return (
            <>
                <section>
                    <div style={{ margin:'100px', marginTop:'75px' }}>
                        <div style={{ ...faqHeadingStyle }}>Frequently Asked Questions</div>
                        <div className='container' style={{ padding: '0px', marginTop: '32px' }}>
                        <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('1')} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle}}>Who can apply for a Business Loan?</div>
                                            {this.state.showAnswer1 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer1 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Tata Capital offers a wide range of business loans to self-employed professionals and non-professionals, including-<br/>
                                                    <div style={{marginLeft:'15px'}}>
                                                    <li style={answerListStyle2}>Self-employed individuals</li>
                                                    <li style={answerListStyle2}>Proprietorship firms</li>
                                                    <li style={answerListStyle2}>Private limited companies</li>
                                                    <li style={answerListStyle2}>Partnership firms</li>
                                                    <li style={answerListStyle2}>Limited liability partnership firms</li>
                                                    <li style={answerListStyle2}>Trusts and Societies</li>
                                                    <li style={answerListStyle2}>Hindu undivided families (HUFs)</li>
                                                    <li style={answerListStyle2}>Closely held public limited companies</li>
                                                    </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('2')} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle}}>What are the rates and fees for business loans from Tata Capital?</div>
                                            {this.state.showAnswer2 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer2 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>With Tata Capital, you can avail of business loans at the most competitive interest rates starting at 13% p.a. for professionals and 15% p.a. for non-professionals. Moreover, we can determine the best interest rate for you depending on your loan eligibility, credit score, income, and other criteria.</li>
                                                    <li style={answerListStyle}>Besides, when you apply for a business loan with us, the loan will include various charges, including processing fees, foreclosure charges, EMI bounce charges, etc., which will be disclosed at the time of loan processing.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={this.handleClick3} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle}}>What documents do I need to apply for a business loan?</div>
                                            {this.state.showAnswer3 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer3 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Tata Capital offers you business loans with easy eligibility and minimal documentation. Here's the list of documents required to avail of a business loan-
                                                    <div style={{marginLeft:'15px'}}>
                                                    <li style={answerListStyle2}>KYC documents, including a photo ID and address proof</li>
                                                    <li style={answerListStyle2}>Business proof, including business registration certificate, GST registration certificate, trade licence, or a certified copy of the partnership deed agreement or sole proprietorship declaration</li>
                                                    <li style={answerListStyle2}>Income proof, such as the last two years' income tax returns (ITRs) and computation of income, the last two years audited finances with the tax audit report, the last two years profit-loss statements, or balance sheet (duly audited by a chartered accountant)</li>
                                                    <li style={answerListStyle2}>Current account bank statements and GST returns for the last six months</li>
                                                    </div>
                                                    </li>
                                                    <li style={answerListStyle}>You may also require some additional documents at the time of loan processing. Visit the <span style={{textDecoration: 'underline', color:'blue'}} onClick={() => this.logincheck('Tata Capital Business Loan', 'https://www.tatacapital.com/business-loan/documents-required.html')} onTouchStart={() => {}}>documents required</span> page for more details.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div> */}
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('4')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>What are the requirements to get a Business Loan?</div>
                                            {this.state.showAnswer4 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer4 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                <li style={answerListStyle}>Business Loan eligibility criteria can a vary from one lending institution to another. To become eligible for a Tata Capital Business Loan, you need to fulfill the following requirements:
                                                    <div style={{marginLeft:'15px'}}>
                                                    <li style={answerListStyle2}>Your age should be at least 21 years at the time of applying for the business loan and not more than 65 years at the time of the last EMI</li>
                                                    <li style={answerListStyle2}>Your credit score should be at least 650 or more</li>
                                                    <li style={answerListStyle2}>Business should be profitable</li>
                                                    <li style={answerListStyle2}>Minimum 2 years business stability</li>
                                                    <li style={answerListStyle2}>Banking balance should be at least Rs. 5000</li>
                                                    </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('5')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>What are my options to repay a Business Loan? </div>
                                            {this.state.showAnswer5 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer5 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>We strive to make the loan repayment process super convenient for you with flexible payments. You can pay your EMIs via various payment instruments. We also allow EMI payment through a direct debit facility, electronic clearance service, or post-dated cheques. </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div style={showMoreStyle} onClick={this.handleShowMoreClick}>{this.state.showMore ? 'Show Less' : 'Show More'}</div>
                            </div>
                            
                            {this.state.showMore && (
                            <>
                            <div className='row' style={{ marginTop: '0px' }}>
                                <div className='col-12'>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('6')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>Can I get a Business Loan without collateral?</div>
                                            {this.state.showAnswer6 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer6 &&(
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Yes, at Tata Capital, you can enjoy 100% collateral-free loans to meet all your urgent business requirements.</li>
                                                </ul>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('7')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>How to get a Business Loan approved for an online business?
                                            </div>
                                            {this.state.showAnswer7 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer7 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>With us, you can also avail of quick loans for your online business. Whether you run an e-commerce website or an online retail store, apply for a loan to fulfil your working capital requirements. You must fulfil the following eligibility criteria to ensure quick approval for your online business loan:<br/>
                                                    <div style={{marginLeft:'15px'}}>
                                                    <li style={answerListStyle2}>Your CIBIL score should be above 700</li>
                                                    <li style={answerListStyle2}>Your business turnover is showing an upward trend</li>
                                                    </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('8')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>Will a Business Loan affect my credit score?</div>
                                            {this.state.showAnswer8 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer8 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Yes. If you have applied for a business loan for your proprietorship or partnership business, the loan will get reflected in your credit report. As a result, any late payments or EMI defaults will also negatively impact this credit score. However, if your business is an LLC, the business loan will only affect your business credit score and not your personal credit score.<br/></li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('9')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>How does a Business Loan affect taxes?</div>
                                            {this.state.showAnswer9 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer9 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>The interest levied on your business loan is eligible for tax deductions because the interest payment on a business loan is a business expense and not revenue. This means you can reduce your taxable income when you apply for a business loan in India. However, note that the principal component of the loan is not tax-deductible.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('10')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>Do I need to have a business account to get a Business Loan?</div>
                                            {this.state.showAnswer10 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer10 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>If you're running a sole proprietorship business, having a business account is not mandatory. However, if you have a partnership business or an LLC, you must have a business account to get a business loan</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('11')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>How much EMI do I need to pay for my Business Loan?</div>
                                            {this.state.showAnswer11 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer11 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Your business loan EMI will depend on several factors, including your loan amount, tenure, and interest rates. To get an idea of the EMI amount you need to pay, use our online business loan EMI calculator.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('12')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>What is the maximum amount of Business Loan I can take?</div>
                                            {this.state.showAnswer12 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer12 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Tata Capital provides business loans of up to Rs. 90 lakhs. However, the maximum loan amount you can secure depends on your loan eligibility and credit score.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('13')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>How long can it take to repay my Business Loan?</div>
                                            {this.state.showAnswer13 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer13 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Tata Capital offers you a longer repayment tenure of up to 60 months. With us, you can easily pick a comfortable repayment tenure based on your business's financial needs and affordability. We also offer various EMI plans to repay the loan as per your income cycle.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('14')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>Is the loan interest rate fixed or floating?</div>
                                            {this.state.showAnswer14 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer14 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Tata At Tata Capital, the interest rate on your business loan is fixed. This means the interest rate and the EMI amount do not change during the course of the loan.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('15')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>What are the Flexi EMI Plans available to me?</div>
                                            {this.state.showAnswer15 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer15 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}><b>Step Up Flexi EMI Plan: </b>This repayment plan lets you pay lower EMIs at the beginning of the tenure and gradually increase the EMI as your income grows. This plan helps you manage your loan repayment as per the income flow.</li>
                                                    <li style={answerListStyle}><b>Step Down Flexi EMI Plan: </b>This repayment plan allows you to pay higher EMIs initially and then decrease them gradually. This plan helps you reduce your interest outcome.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('16')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>What are the qualifying criteria for Business Loan approval?</div>
                                            {this.state.showAnswer16 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer16 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>At Tata Capital, the qualifying criteria for business loan approval are quite simple. Here are the requirements you need to fulfil-<br/>
                                                    <div style={{marginLeft:'15px'}}>
                                                    <li style={answerListStyle2}>Turnover of Rs. 50,000+ p.a.</li>
                                                    <li style={answerListStyle2}>Minimum two years of business stability</li>
                                                    <li style={answerListStyle2}>Business should be profitable</li>
                                                    <li style={answerListStyle2}>A minimum banking balance of Rs. 10,000</li>
                                                    </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('17')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>How do I know the status of my Business Loan application?</div>
                                            {this.state.showAnswer17 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer17 &&
                                            <div>
                                                        <ul style={{ marginTop: '10px' }}>
                                                            <li style={answerListStyle}>You can track the status of your business loan application. You can also contact your sales representative for details.
                                                                In case of any issues or queries, you can call us at 1860-267-6060. Additionally, you can also write to us at contactus@tatacapital.com or visit your nearest Tata Capital branch.
                                                            </li>
                                                        </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('18')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>What are the different channels for servicing?</div>
                                            {this.state.showAnswer18 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer18 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Tata Capital offers various Ways to Service:<br/>
                                                    <div style={{marginLeft:'15px'}}>
                                                    <li style={answerListStyle2}>Say 'Hi' on Tata Capital's WhatsApp number, 7506756060, from your registered mobile number.</li>
                                                    <li style={answerListStyle2}>Login to Tata Capital's Mobile App. Download the Tata Capital mobile app</li>
                                                    <li style={answerListStyle2}>Log into the Tata Capital website</li>
                                                    <li style={answerListStyle2}>Call our Customer Care Number 1860 267 6060</li>
                                                    <li style={answerListStyle2}>Email Us at contactus@tatacapital.com for a new loan requirement</li>
                                                    <li style={answerListStyle2}>Visit Tata Capital's service branches. Find your nearest branch</li>
                                                    <li style={answerListStyle2}>Send us an SMS from your registered mobile number to 97059 66060.</li>
                                                    <li style={answerListStyle2}>Interact with TIA to learn more about Tata Capital's products and services. TIA is also available on Google Assistant & Alexa.</li>
                                                    </div>
                                                    </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('19')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>What are the different types of Charges that can be levied in a Business loan?</div>
                                            {this.state.showAnswer19 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer19 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>You can get a business loan with Tata Capital at attractive interest rates starting at 12%-15% for self-employed doctors, at 13%-17.50% for self-employed professionals other than doctors, and at 15%-25% for non-professionals.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('20')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>I want to change the bank from which my EMI is being debited.</div>
                                            {this.state.showAnswer20 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer20 &&
                                            <div>
                                                <div>Here's how you can do it-</div>
                                                <div><b>For Retail Loans:</b></div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}><b>If your bank is a participant in digital E-Nach registration, then follow these steps-</b><br/>
                                                    <div style={{marginLeft:'15px'}}>
                                                    <li style={answerListStyle2}>Raise the request by logging into Tata Capital's website. Login here.</li>
                                                    <li style={answerListStyle2}>You can also send us a request via email at customercare@tatacapital.com with a scanned image of your cancelled cheque (with your name on it) of your new bank account or the account statement with the bank's details with IFSC (Indian Financial System Code).</li>
                                                    <li style={answerListStyle2}>After receiving the request with the document, we will send you an SMS with an E-NACH registration link to your registered mobile number.</li>
                                                    <li style={answerListStyle2}>You are requested to complete the E-NACH registration. Once you've successfully registered, the new bank account details will be updated in our records.</li>
                                                    </div>
                                                    </li>

                                                    <li style={{...answerListStyle, marginTop:'10px'}}><b>If your bank is NOT a participant in digital E-Nach registration, here's what you need to do-</b><br/>
                                                    <div style={{marginLeft:'15px'}}>
                                                                    <li style={answerListStyle2}>Since your bank is not a part of digital e-NACH registration, you need to provide some physical documents. You can contact us to place a request for your Bank NACH change. Our service executive will contact you on your registered mobile number to fix an appointment at your convenience and collect the below documents.<br/>
                                                                        1. Photocopy of a cancelled personalised cheque from the new bank account<br/>
                                                                        2. Your consent and signature on the new NACH mandate form (the executive will carry this form along with him)<br/>
                                                                        Please note that it takes about 30 days for the NACH to be registered with the bank.<br/>
                                                                        Note: EMI will be presented to the existing bank till the time your new bank account gets updated in our records.
                                                                    </li>
                                                                </div>
                                                            </li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('21')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>How do I download the NOC for Business Loan Account?</div>
                                            {this.state.showAnswer21 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer21 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Once your loan is closed, the NOC will be dispatched to your address within five working days. However, if you want to receive the NOC at a different address or at the nearest Tata Capital branch, you can contact us.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('22')} style={faqClickableDiv}>
                                            <div style={faqQuestionStyle}>How do I download my Business Loan's Statement of Account?</div>
                                            {this.state.showAnswer22 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto'}} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto'}} />
                                            )}
                                        </div>
                                        {this.state.showAnswer22 &&
                                            <div>
                                                <ul style={{marginTop:'10px'}}>
                                                    <li style={answerListStyle}>Once you log in to your account, you will be redirected to a page where you can select the Loan Account Number to download your Business Loan's statement and welcome.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>


                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                </div>
                            </div>
                            </>)}
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default TataCapitalFAQsComponent