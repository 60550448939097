import React, { useEffect, useState } from 'react'
import {Modal} from 'react-bootstrap';
import Cookies from 'universal-cookie';
import axios from 'axios';

import {geturldata, gatrack, glidtracker, clicktracking } from '../../utils';

function ModalComponent(props){

    const[show,setShow] = useState(true);
    const[url_selector,setUrl_selector] = useState('Apply Now');

    function getcookieready(dataarray) {
        const formattedString = Object.entries(dataarray)
            .map(([key, value]) => `${key}=${value}`)
            .join('|');
    
        return formattedString;
    }
    
    function checkApplication(glid,lender){
        var host = window.location.hostname;
        if (host == 'loans.indiamart.com') {
            var checkAppURL = "https://loans.indiamart.com/functions.php";
        }
        else {
            var checkAppURL = "https://dev-loans.indiamart.com/functions.php";
        }

        var checkAppData = {
            "glid": glid,
            "lender":lender,
            "functionName": "checkApplication",
            // "respFlag":'1'
        };

        var formData = new FormData();
        for (var key in checkAppData) {
            formData.append(key, checkAppData[key]);
        }

        var self = this;

        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: checkAppURL,
            data: formData,
            timeout: 10000
        }).then((response) => {
            if (response.data == true) {
                setUrl_selector("Continue");
                //console.log("Url selector=>",url_selector);
                
                var vendor_name = props.vendor_name;
                if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan")
                {
                    glidtracker(vendor_name, "Continue");
                    gatrack('Apply for loan',vendor_name,'Click');
                    clicktracking(vendor_name);
                }
                else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity")
                {
                    geturldata(vendor_name, "Continue");
                    var product_name = vendor_name + " Business Loan"
                    gatrack('Apply for loan',product_name,'Click');
                    clicktracking(product_name);                              
                }
                else
                {
                    gatrack('Apply for loan',vendor_name,'Click');
                    clicktracking(vendor_name);
                    window.open(props.vendor_url,'_blank');
                } 

            }
            else{
                var vendor_name = props.vendor_name;
                if(vendor_name == "ICICI Business Loan" || vendor_name == "Tata Capital Business Loan" || vendor_name == "Poonawalla Business Loan")
                {
                    glidtracker(vendor_name, "Apply Now");
                    gatrack('Apply for loan',vendor_name,'Click');
                    clicktracking(vendor_name);
                }
                else if(vendor_name == "CreditEnable Secured" || vendor_name == "CreditEnable Unsecured" || vendor_name == "Pirimid" || vendor_name == "CreditMantri" || vendor_name == "LoanTap" || vendor_name == "OneInfinity")
                {
                    geturldata(vendor_name, "Apply Now");
                    var product_name = vendor_name + " Business Loan"
                    gatrack('Apply for loan',product_name,'Click');
                    clicktracking(product_name);                              
                }
                else
                {
                    gatrack('Apply for loan',vendor_name,'Click');
                    clicktracking(vendor_name);
                    window.open(props.vendor_url,'_blank');
                }
            }
        }).catch((error) => {
            console.error("Error:", error);
        });
    }

    function submitbtnClick(){
        const mob = document.getElementById('phoneNumber').value;
        if(!mob || mob.length != 10 || ! ['6', '7', '8', '9'].includes(mob.charAt(0))){
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';
            document.getElementById("phoneNumber").style.borderColor = 'red';
    
            if(!mob) {
                document.getElementById("mobile_err").style.display = 'block';
            }
            else if(mob.length != 10) { 
                document.getElementById("mobile_err2").style.display = 'block';
            }
            else if(! ['6', '7', '8', '9'].includes(mob.charAt(0))) {
                document.getElementById("mobile_err3").style.display = 'block';
            }
        }
        else{
            document.getElementById("mobile_err").style.display = 'none';
            document.getElementById("mobile_err2").style.display = 'none';
            document.getElementById("mobile_err3").style.display = 'none';
            document.getElementById("phoneNumber").style.borderColor = '';
    
            var host = window.location.hostname;
    
                if(host == 'loans.indiamart.com'){
                    var identifyurl = 'https://loans.indiamart.com/functions.php';
                }
                else{
                    var identifyurl = 'https://dev-loans.indiamart.com/functions.php';
                }
    
                var identifyData = {
                    "functionName" :"identify",
                    "mobile" : mob
                };
    
                var formData = new FormData();
                for (var key in identifyData) {
                    formData.append(key, identifyData[key]);
                }
    
                axios({
                    method: 'post',
                    headers: { 'Content-Type': 'application/x-www-form-urlencoded'},
                    url: identifyurl,
                    data: formData,
                    timeout:10000
                  }).then(function (response) {
                    var data_obj = response['data'];
                    
                    if(data_obj.code == '200') {
                        var glid = data_obj.DataCookie.glid;
                        var cookiestring = getcookieready(data_obj.DataCookie);
    
                        const expirationDate = new Date();
                        expirationDate.setDate(expirationDate.getDate() + 2);
    
                        const cookie = new Cookies();
                        cookie.set('ImeshVisitor', cookiestring, { path: '/', expires: expirationDate });
                        checkApplication(glid, props.lender_name);
                        setShow(false);   
                        //window.open(props.url+glid,"_blank");
                    }
                })
        }
    }

    function handleCloseClick(){
        props.handleClose();
    }

    return(
        <Modal 
            show={show}
            backdrop="static"
            keyboard={false}
            id="loginpopupmodal" 
            style={{marginTop:'200px',padding:'10px'}}
            onHide={handleCloseClick}>
                    
                    <Modal.Header style={{backgroundColor: '#2E3192', padding: '6px 16px'}}>
                        <Modal.Title className="fs-5" style={{color: '#ffff', fontWeight: '700', fontSize: '20px !important'}} >Sign Innnn</Modal.Title>
                        <button type="button" className="custom-close-button" onClick={handleCloseClick} style={{ background: 'none', border: 'none', cursor: 'pointer', color: '#fff', fontSize: '18px', fontWeight: 'bold', padding: '0', position: 'absolute', top: '4px', right: '16px' }}>
                            &times;
                        </button>
                    </Modal.Header>

            <Modal.Body >
                <label htmlFor="phoneNumber" style={{ marginBottom: '10px',marginLeft:'3px'}}>Mobile Number linked to IndiaMART account</label>
                <input type="tel" maxLength="10" placeholder="Enter your IndiaMART account mobile number" id="phoneNumber" className="un2_s" style={{backgroundColor: 'rgb(255, 255, 255)', outline: 'none', boxSizing: 'content-box', paddingLeft: '5px', width: '98%', height: '30px', margin: 'auto'}} pattern="[0-9]{10}" required/>
                <span id="mobile_err" className="em-1" style={{display: 'none', fontSize: '12px', marginLeft: '3px'}}>Please enter mobile number</span>
                <span id="mobile_err2" className="em-1" style={{display: 'none', fontSize: '12px', marginLeft: '3px'}}>Please enter ten digit mobile number</span>
                <span id="mobile_err3" className="em-1" style={{display: 'none', fontSize: '12px', marginLeft: '3px'}}>Mobile Number should start with 6,7,8 or 9</span>
                <button id="submitBtn" className="continue_s" style={{padding: '8px 0px', width: '180px', backgroundColor: 'rgb(0, 166, 153)', margin: '15px auto',display:'block',marginBottom:'5px'}} onClick={submitbtnClick} >Submit</button>

            </Modal.Body> 
        </Modal>
    )
}

export default ModalComponent;

//