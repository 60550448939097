import React, { Component } from 'react'

import downwardArrowImage from '../../Images/lenderimages/faqDownArrow.svg';
import rightArrowImage from '../../Images/lenderimages/faqRightArrow.svg';

const faqHeadingStyle = {
    background: 'linear-gradient(91deg, #1A54AB 0%, #28B6BF 103.91%)',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    fontFamily: 'Arial',
    fontSize: '32px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '32px',
    textTransform: 'capitalize',
}

const faqQuestionStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '49px',
    marginRight: '20px',
    textAlign: 'justify',
}

const borderLine = {
    borderTop: '1px solid rgba(9, 30, 66, 0.70)',
    height: '1px',
    opacity: '0.5',
}

const faqClickableDiv = {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center'
}

const answerListStyle = {
    marginRight: '40px',
    textAlign: 'justify',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '17px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '30px',
}

export class IciciFAQsComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,
            showAnswer5: false,
        };
        this.resetStates = this.resetStates.bind(this);
    }

    resetStates() {
        this.setState({
            showAnswer1: false,
            showAnswer2: false,
            showAnswer3: false,
            showAnswer4: false,
            showAnswer5: false,
        });
        
    }

    handleClick = (number) => {
        this.resetStates();
        if(number === '1'){
            this.setState({showAnswer1:!this.state.showAnswer1});
        }
        else if(number === '2'){
            this.setState({showAnswer2:!this.state.showAnswer2});
        }
        else if(number === '3'){
            this.setState({showAnswer3:!this.state.showAnswer3});
        }
        else if(number === '4'){
            this.setState({showAnswer4:!this.state.showAnswer4});
        }
        else if(number === '5'){
            this.setState({showAnswer5:!this.state.showAnswer5});
        }

    }

    render() {
        return (
            <>
                <section>
                    <div style={{ margin: '100px' }}>
                        <div style={{ ...faqHeadingStyle }}>Frequently Asked Questions</div>
                        <div className='container' style={{ padding: '0px', marginTop: '32px' }}>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('1')} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle }}>Is this "InstaOD Plus" product available only for ICICI customers?</div>
                                            {this.state.showAnswer1 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer1 &&
                                            <div style={{ marginTop: '10px' }}>
                                                <ul>
                                                    <li style={answerListStyle}>No, it is open to customers of all banks.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('2')} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle }}>What should be the minimum business vintage?</div>
                                            {this.state.showAnswer2 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer2 &&
                                            <div style={{ marginTop: '10px' }}>
                                                <ul>
                                                    <li style={answerListStyle}>2 years.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('3')} style={faqClickableDiv}>
                                            <div style={{ ...faqQuestionStyle }}>Is there any foreclosure charges?</div>
                                            {this.state.showAnswer3 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer3 &&
                                            <div>
                                                <ul style={{ marginTop: '10px' }}>
                                                    <li style={answerListStyle}>No.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('4')} style={faqClickableDiv}>
                                            <span style={faqQuestionStyle}>What is the maximum and minimum loan amount?</span>
                                            {this.state.showAnswer4 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer4 &&
                                            <div>
                                                <ul style={{ marginTop: '10px' }}>
                                                    <li style={answerListStyle}>Minimum ₹50,000 and Maximum ₹50,00,000 for existing customers and ₹25,00,000 for new customers.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                    <div style={{ marginTop: '24px' }}>
                                        <div onClick={() => this.handleClick('5')} style={faqClickableDiv}>
                                            <span style={faqQuestionStyle}>What is the tenure of InstaOD?</span>
                                            {this.state.showAnswer5 ? (
                                                <img src={downwardArrowImage} alt="downward arrow" style={{ marginLeft: 'auto' }} />
                                            ) : (
                                                <img src={rightArrowImage} alt="right arrow" style={{ marginLeft: 'auto' }} />
                                            )}
                                        </div>
                                        {this.state.showAnswer5 &&
                                            <div>
                                                <ul style={{ marginTop: '10px' }}>
                                                    <li style={answerListStyle}>12 months.</li>
                                                </ul>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className='row' style={{ marginTop: '24px' }}>
                                <div className='col-12'>
                                    <div style={borderLine}></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        )
    }
}

export default IciciFAQsComponent