import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';

import {getGlid, clicktracking, gatrack, glidtracker} from '../../utils';

import feature1 from '../../Images/productsimages/feature1.png'
import feature2 from '../../Images/productsimages/feature2.png'
import feature3 from '../../Images/productsimages/feature3.png'
import feature4 from '../../Images/productsimages/feature4.png'

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    background: '#EDF5FC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px'
};

const buttonstyle = {
    width: '199px',
    height: '54px',
    flexShrink: '0',
    borderRadius: '10.595px',
    background: 'var(--Brand1, #2E3192)',
    borderStyle: 'none',
    color: '#FFF',
    fontFamily: 'Arial',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: 'normal',
    marginTop : '-10px'
};

const emiIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#FCF1ED',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const tenureIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#FCF9ED',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const pfeeicon = {
    width: '56px',
    height: '56px',
    flexShrink: '0',
    borderRadius: '11.231px',
    background: '#ECECEC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)'
}

const eligcriteria = {
    paddingTop:'35px',
    textTransform: 'capitalize',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '20px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '100%',
    textAlign:'left',
    paddingLeft:'32px',
    paddingBottom:'24px' 
}

const featuretextstyle = {
    marginBottom: '10px',
    color: '#626262',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '147.023%' 
}

const featurevaltextstyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '147.023%'
}

const docstextstyle = {
    textAlign:'left', 
    marginLeft:'40px',
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '186%' /* 33.48px */
}

export class IIFLProductComponent extends Component {
    logincheck = (vendor_name, vendor_url) => {
        var glid = getGlid();

        if (!glid) {
            window.open(vendor_url, '_blank');
        }
        else {
            glidtracker(vendor_name, "Apply Now");
            gatrack('Apply for loan', vendor_name, 'Click');
            clicktracking(vendor_name);
        }

    }
  render() {
    return (
        <>
        <div className='container sec03 text-center' style={{paddingTop:'65px'}}>
            <div className='row' style={{marginLeft:'10px'}}>
                <div className='col-12' style={{textAlign:'left', paddingBottom:'48px'}}>
                    <span>Types of Products</span>
                </div>
            </div>

            <section style={{background:'#F5FAFF', paddingBottom:'50px'}} >

                <div className='row' style={{border: '1px #2E3192', borderStyle: 'none none solid none',paddingTop:'59px', textAlign:'left', paddingLeft:'40px', marginLeft:'15px', marginRight:'10px'}}>
                    <div className='col-6 productname' style={{paddingBottom:'35px' , marginLeft:'-40px'}}>
                    <strong>Unsecured Business Loans</strong>
                    </div>
                    <div className='col-6' style={{textAlign:'right'}}>
                        <button style={buttonstyle} onClick={() => this.logincheck('IIFL Business Loan', 'https://iifl-loans.finbox.in/?partnerCode=II_YATOYJ&customerID=')}>Apply Now</button>
                    </div>
                </div>
                <div style={{ marginTop: '32px' }}>

                        <div className="container" style={{paddingLeft:'30px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                    <div className="d-flex" style={{textAlign:'left'}}>
                                        <div style={expandingIconStyle}>
                                            <img src={feature1} alt="Int Rate Icon" />
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            Interest Rate:<br/> <strong style={{display:'flex'}}><div style={{marginRight:'3px',fontSize:'16px',position:'relative',top:'1px'}}>Starts</div> @ 18%</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="d-flex " style={{textAlign:'left'}}>
                                        <div style={emiIconStyle}>
                                            <img src={feature2} alt="EMI Icon" style={{paddingTop:'8px'}}/>
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                            EMI/Lakh: <br/> <strong style={{display:'flex'}}><div style={{marginRight:'3px',fontSize:'16px',position:'relative',top:'1px'}}>Starts</div> @ ₹9200</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="d-flex " style={{textAlign:'left'}}>
                                        <div  style={tenureIconStyle}>
                                            <img src={feature3} alt="Tenure Icon" style={{paddingTop:'8px'}}/>
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px' }}>
                                                Tenure: <br/> <strong>Min. 1 year</strong>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3">
                                    <div className="d-flex " style={{textAlign:'left'}}>
                                        <div style={pfeeicon}>
                                            <img src={feature4} alt="PPfees Icon" style={{paddingTop:'8px'}}/>
                                        </div>
                                        <div className="ml-2" style={{ ...iconsTextStyle, marginLeft: '16px',whiteSpace:'nowrap' }}>
                                                Processing Fee: <br/> <strong style={{display:'flex'}}><div style={{marginRight:'3px',fontSize:'16px',position:'relative',top:'1px'}}>Starts</div> @ 2%</strong>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                <div style={{marginTop:'40px', marginLeft:'40px',background: '#D5E4F2',borderRadius: '16px', paddingBottom:'32px', marginRight:'40px'}}>
                        <div style={eligcriteria}>
                            Eligibility Criteria
                        </div>

                        <div>
                        <div className="container" style={{paddingLeft:'30px'}}>
                            <div className="row">
                                <div className="col-lg-3">
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Age</div>
                                    <div style={featurevaltextstyle}>Minimum 23 years</div>
                                </div>

                                </div>
                                <div className="col-lg-3">
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Business Vintage</div>
                                    <div style={featurevaltextstyle}>Minimum 2 years</div>
                                </div>
                                </div>
                                <div className="col-lg-3">
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Annual Turnover</div>
                                    <div style={featurevaltextstyle}>Minimum ₹20 lakh yearly</div>
                                </div>
                                </div>
                                <div className="col-lg-3">
                                <div className="d-flex flex-column" style={{textAlign:'left'}}>
                                    <div style={featuretextstyle}>Cibil Score</div>
                                    <div style={featurevaltextstyle}>675 & above</div>
                                </div>
                                </div>
                            </div>
                        </div>
                        </div>
                </div>

                <div style={{marginTop:'0px'}}>
                <div style={eligcriteria}>
                            Required Documents
                        </div>
                <div style={docstextstyle}>
                 <li>Selfie of the applicant</li>
                 <li>ID Proof- PAN card</li> 
                 <li>Address Proof- Aadhar card/Driving License</li>
                 <li>Current Account / Savings Account bank statement for 6-12 months</li>
                </div>    
                </div>


            </section>

            
            </div>    
        </>
    )
  }
}

export default IIFLProductComponent