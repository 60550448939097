import React, { Component } from 'react';
import axios from 'axios';

import {geturldata, gatrack, glidtracker, getGlid } from '../../utils';

import "../carousel/slick/slick.css";
import "../carousel/slick/slick-theme.css"
import Slider from 'react-slick';

const sliderstyle = {
    cursor : 'pointer',
    width: '100%' ,
    maxWidth: '1200px',
    // paddingTop: '5px',
    margin: '0 auto',
    height:'150px'
};

function clicktracking(vendor_name) {

    var glid = getGlid();
    var host = window.location.hostname;

    if(host == 'loans.indiamart.com'){
        var getlenderorderURL = "https://loans.indiamart.com/functions.php";
    }
    else{
        var getlenderorderURL = "https://dev-loans.indiamart.com/functions.php";
    }

    var getlenderorderData = {
        "glid" : glid,
        "functionName" : "StoreLenderOrderData",
        "lenderorder" : 'NA',
        "action" : "Apply Now Click",
        "ApplyNow" : vendor_name,
        "device" : "Desktop" 
    };

    var formData = new FormData();
    for (var key in getlenderorderData) {
        formData.append(key, getlenderorderData[key]);
    }

    if(glid)
    {
        axios({
            method: 'post',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            url: getlenderorderURL,
            data: formData,
            timeout:10000
            }).then(function (response) {
            })
    }

}

class CarouselComponent extends Component 
{
    scrollToElement = (scrolledto) => {
          const element = document.getElementById(scrolledto);
          
          if (element) {
            const yOffset = element.getBoundingClientRect().top + window.pageYOffset;
            window.scrollTo({ top: yOffset, behavior: 'smooth' });
          }
    };

    oneinfinitybannertrigger = () => {
        geturldata('OneInfinity', 'Apply Now');
        gatrack('Apply for loan',"OneInfinity Business Loan",'Click');
        clicktracking("OneInfinity Business Loan"); 
    }

    poonawallabannertrigger = () => {
        glidtracker('poonawalla', "Apply Now");
    }

    indialendsbannertrigger = () => {
        var glid = getGlid();
        if(glid)
        {
            geturldata('IndiaLends', 'Apply Now');
        }
        else
        {
            window.open(' https://indiamart.indialends.com/', '_blank');
        }
        gatrack('Apply for loan',"IndiaLends Business Loan",'Click');
        clicktracking("IndiaLends Business Loan"); 
    }

    handleVeloBannerClick = () => {
        var glid = getGlid();
        if(glid){
            geturldata('Velocity', 'Apply Now');
        }
        else{
            window.open('https://indiamart.velocity.in/marketplace/indiamart?', '_blank');
        }
        gatrack('Apply for loan',"Velocity",'Click');
        clicktracking("Velocity"); 
    }

    render()
    {
        const settings = {
            autoplay:true,
            autoplaySpeed:5000,
            arrows:false,
            centerMode:false,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            variableWidth: false,
            adaptiveHeight: true
          };
        var glid = getGlid();
        

        var shouldRenderIndialendsBanner = glid.endsWith('2') || glid.endsWith('3') || glid.endsWith('4') || glid == '';

        var isVeloVisible = (glid == '59002668') || (glid == '13035342' || glid.endsWith('6') || glid.endsWith('7') || glid.endsWith('8') || glid.endsWith('9')) && (localStorage.getItem("showVeloBanner") !== null && localStorage.getItem("showVeloBanner") !== undefined && localStorage.getItem("showVeloBanner") == 1 ) && ( localStorage.getItem("vin") !== null && localStorage.getItem("vin") !== undefined && localStorage.getItem("vin") > 12 )  && (localStorage.getItem("turnover") !== null && localStorage.getItem("turnover") !== undefined && localStorage.getItem("turnover") == '1') ;

        return (
            <section style={{  paddingLeft : '5px', paddingRight : '5px', height:'150px', overflow:'hidden',marginTop:'50px'}}>

                <Slider {...settings} style={{backgroundColor:'#F8F9FA'}}>
                    <div>
                        <img style={sliderstyle} src="https://loans.imimg.com/images/loans/headerimages/MainBannerDesktop.png" alt="Main Banner" onClick={() => this.scrollToElement('lenders')}
                        />
                    </div>
                    {/* <div >
                        <img style={sliderstyle} src={sliderbanner4} alt="Poonawalla Banner" onClick={() => this.poonawallabannertrigger()}/>
                    </div> */}

                    {/* <div>
                        <img style={sliderstyle} src={sliderbanner2} alt="Oneinfinity Banner" onClick={() => this.oneinfinitybannertrigger()}
                        />
                    </div> */}
                    <div >
                        <img style={sliderstyle} src="https://loans.imimg.com/images/loans/headerimages/DesktopBanners2.png" alt="All at one place" onClick={() => this.scrollToElement('lenders')}
                        />
                    </div>
                    {/* {shouldRenderIndialendsBanner && (
                    <div >
                        <img style={sliderstyle} src='https://loans.imimg.com/images/loans/lenderimages/indialends_web.png' alt="IndiaLends Banner" onClick={() => this.indialendsbannertrigger()}
                        />
                    </div>)
                    } */}

                    {
                    isVeloVisible && (
                    <div >
                        <img style={sliderstyle} src={'https://loans.imimg.com/images/loans/lenderimages/velo_banner.png'} alt="Velocity Banner" onClick={this.handleVeloBannerClick}
                        />
                    </div>)
                    }

                </Slider>
            </section>
        );
    }

}


export default CarouselComponent;
