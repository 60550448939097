import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import axios from 'axios';

import {getGlid, clicktracking, gatrack, geturldata} from '../../utils';

import lenderbanner from '../../Images/lenderimages/OneInfinityBanner.png'
import benefit1 from '../../Images/LenderIntroimages/benefit1.png'
import benefit2 from '../../Images/LenderIntroimages/benefit2.png'
import benefit3 from '../../Images/LenderIntroimages/benefit3.png'
import benefit4 from '../../Images/LenderIntroimages/benefit4.png'
import benefit5 from '../../Images/LenderIntroimages/benefit5.png'

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    background: '#EDF5FC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    marginLeft: '16px',
    textAlign:'left'
};
  
class LenderIntroComponent extends Component
{
    logincheck = (vendor_name, vendor_url) =>
    {
        var glid = getGlid();
        
        if(!glid)
        {
            window.open(vendor_url,'_blank');
        }
        else
        {
            geturldata(vendor_name, "Apply Now");
            gatrack('Apply for loan',vendor_name,'Click');
            clicktracking(vendor_name);
        }
        
    }

    render()
    {
        return (
            <>
            <div className='row' style={{paddingTop:'32px', paddingBottom:'32px' , marginLeft:'10px' , marginRight:'10px' , textAlign:'center',marginTop:'40px'}}>
                    <div className='col-12' style={{cursor:'pointer'}} onClick={() => this.logincheck('OneInfinity', 'https://complementor.oneflo.in/complementor-widget/bil/CONN4128778344?')}> <img src={lenderbanner} alt='Lender Banner' style={{maxWidth:'1200px',width:'97.1%'}}/> </div>
            </div>

            <div className="container sec02 text-center">
                <div className='row'>
                    <div className='col-12' >
                    <h2 className="title" style={{ textAlign:'left', marginLeft:'28px'}}><span>OneInfinity</span></h2>
                    </div>
                </div>

                <div className='row' style={{marginTop:'32px' , textAlign:'left', marginLeft:'20px'}}>
                    <div className='col-12 aboutlender' style={{paddingRight:'58px',textAlign:'justify'}}>
                    OneInfinity is your one stop provider for all financial products like loans and insurance. In business for
                    nearly a decade now, we help you avail loans from 85+ trusted Banks & NBFCs. We are the first to integrate via
                    API with many of the lenders that result in multifold benefits for the borrowers on our platform including
                    higher limits, lower interest costs, quicker sanctions and paperless journeys. We offer both online & offline support to help consumers complete their journey and help them avail special offers & policy deviations from lenders. Our ~200 on ground team and offices across Mumbai, Delhi, Pune and many other cities help to address customer queries.
                    </div>
                </div>

                <div className='row' style={{marginTop:'35px' , textAlign:'left', marginLeft:'20px'}}>
                    <div className='col-12 lenderheader'>
                        Benefits of selecting OneInfinity for Business Loan:
                    </div>
                </div>

                <div style={{ marginTop: '32px' }}>

                            <div className="container" style={{paddingLeft:'30px'}}>
                                <div className="row">
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div style={expandingIconStyle}>
                                                <img src={benefit1} alt="Starting Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Starting a business
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div style={expandingIconStyle}>
                                                <img src={benefit2} alt="Expanding Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Expanding Operations
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div id='capitalIconStyle'>
                                                <img src={benefit3} alt="Capital Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Working Capital Requirements
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div id='assetIconStyle'>
                                                <img src={benefit4} alt="Asset Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Asset or Inventory Purchase
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row" style={{ marginTop: '33px' }}>
                                    <div className="col-lg-3 d-flex align-items-center">
                                        <div className="d-flex align-items-center">
                                            <div id="machineryIconStyle">
                                                <img src={benefit5} alt="Machinery Icon" />
                                            </div>
                                            <div className="ml-2" style={{ ...iconsTextStyle}}>
                                                Upgrading Machinery
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
            </div>
            </>


        );
    }
}

export default LenderIntroComponent;