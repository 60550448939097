import React, { Component } from 'react'

import HeaderComponent from '../header/HeaderComponent'
import IIFLIntroComponent from '../lenderintro/IIFLIntroComponent'
import IIFLProductComponent from '../products/IIFLProductComponent'
import IIFLFAQsComponent from '../lenderFAQs/IIFLFAQsComponent'
import FooterComponent from '../footer/FooterComponent'

export class IIFLLenderPage extends Component {
  render() {
    return (
      <>
        <HeaderComponent/>
        <IIFLIntroComponent/>
        <IIFLProductComponent/>
        <IIFLFAQsComponent/>
        <FooterComponent/>
      </>
    )
  }
}

export default IIFLLenderPage