import React, { Component } from 'react'
import Cookies from 'universal-cookie';
import axios from 'axios';
import {Modal} from 'react-bootstrap';

import {getGlid, clicktracking, gatrack} from '../../utils';

import lenderbanner from '../../Images/lenderimages/iciciDesktopBanner.jpg'
import benefit1 from '../../Images/LenderIntroimages/benefit1.png'
import benefit2 from '../../Images/LenderIntroimages/benefit2.png'
import benefit3 from '../../Images/LenderIntroimages/benefit3.png'
import benefit4 from '../../Images/LenderIntroimages/benefit4.png'
import benefit5 from '../../Images/LenderIntroimages/benefit5.png'
import ModalComponent from '../common/ModalComponent';

const expandingIconStyle = {
    width: '56px',
    height: '56px',
    flexShrink: 0,
    borderRadius: '11.231px',
    background: '#EDF5FC',
    boxShadow: '0px 11.23077px 44.92308px 0px rgba(46, 49, 146, 0.04)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

const iconsTextStyle = {
    color: '#091E42',
    fontFamily: 'Arial',
    fontSize: '18px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '28px',
    marginLeft: '16px',
    textAlign:'left'
};

const pointerStyle = {
    color: 'rgb(46, 49, 146)',
    fontSize: '1.8em',
    marginRight: '10px',
    top: '5px',
    position: 'relative',
}

const listStyle = {
    listStyle: 'none',
    textAlign: 'left',
    fontSize: '18px',
    lineHeight: '28px'
}

function iciciglidtracker() {

    var glid = getGlid();

    var url = "https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus";

    if (glid) {
        url += ("&glid=" + glid);

        window.open(url, '_blank');
    }
    else {
        window.open(url, '_blank');
    }
}

function getcookieready(dataarray) {
    const formattedString = Object.entries(dataarray)
        .map(([key, value]) => `${key}=${value}`)
        .join('|');

    return formattedString;
}

export class IciciIntroComponent extends Component {
    constructor(props){
        super(props);
        this.state = {show:false};
    }
    logincheck = (vendor_name, vendor_url) => {
        var glid = getGlid();

        if (!glid) {
            this.setState({show:true});
            // window.open(vendor_url, '_blank');
            gatrack('Non Logged in User','Popup Opened - Other Webpages','Click');
        }
        else {
            iciciglidtracker();
            gatrack('Apply for loan', vendor_name, 'Click');
            clicktracking(vendor_name);
        }

    }
    
    toggleModal = () => {
        this.setState(prevState => ({
            show: !prevState.show
        }));
    }

    render() {
        
        return (
            <>
                <div className='row' style={{ paddingTop: '32px', paddingBottom: '32px', marginLeft: '10px', marginRight: '10px', textAlign: 'center',marginTop:'40px' }}>
                    <div className='col-12' style={{ cursor: 'pointer' }} onClick={() => this.logincheck('ICICI Business Loan', 'https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus')}> <img src={lenderbanner} alt='Lender Banner' style={{ maxWidth: '1200px', width: '97.1%' }} /> </div>
                </div>

                <div className="container sec02 text-center">
                    <div className='row'>
                        <div className='col-12' >
                            <h2 className="loanTapTitle" style={{ textAlign: 'left', marginLeft: '28px' }}><span>ICICI</span></h2>
                        </div>
                    </div>

                    <div className='row' style={{ marginTop: '32px', textAlign: 'left', marginLeft: '20px' }}>
                        <div className='col-12 aboutlender' style={{ paddingRight: '58px', textAlign: 'justify' }}>
                            ICICI was formed in 1955 at the initiative of the World Bank, the Government of India and representatives of Indian industry. The principal objective was to create a development financial institution for providing medium-term and long-term project financing to Indian businesses.
                            <div style={{ marginTop: '10px' }}>
                                ICICI Bank offer InstaOD Plus which is an unsecured Overdraft (OD) facility that can be availed online without any paperwork. The facility serves day-to-day needs as well as sudden exigencies, for business. Under the program, Existing ICICI Bank Current Account customers can avail an instant Overdraft limit of up to ₹50,00,000 and Non ICICI Bank Current Account customers can avail an instant Overdraft limit of up to ₹25,00,000.
                            </div>
                        </div>

                    </div>
                    <div className='row' style={{ marginLeft: '20px', marginTop: '10px' }}>
                        <div>
                            <li style={listStyle}><span style={{ ...pointerStyle }}>•</span>Pay as you use, Interest charged only on the amount drawn.</li>
                            <li style={listStyle}><span style={{ ...pointerStyle }}>•</span>No financials required.</li>
                            <li style={listStyle}><span style={{ ...pointerStyle }}>•</span>Digital DIY (Do it Yourself) journey with disbursement in few clicks.</li>
                        </div>
                    </div>
                </div>

                {   
                    this.state.show && 
                    <ModalComponent url={'https://clos.icicibank.com/CAOOD/Apply.jsp?channelId=IndiaMART&utm_source=IndiaMART&utm_medium=BankStatement&utm_campaign=Overdraft&utm_id=InstaOD+Plus&glid='} handleClose={this.toggleModal} />
                }

            </>
        )
    }
}

export default IciciIntroComponent