import React, { Component } from 'react'
import LenderComponent from '../lender/LenderComponent'

import downwardArrow from '../../Images/applicationsImages/downwardArrow.svg'
import rightArrow from '../../Images/applicationsImages/RightArrow.svg'
import partitionLine from '../../Images/applicationsImages/partitionLine.svg'


const currentAppBoxStyle = {
  height: '56px',
  width: '81%',
  backgroundColor: 'rgba(255, 255, 255, 0.60)',
  display: 'flex',
  alignItems: 'center',
}

const currentAppTextStyle = {
  fontFamily: 'Arial',
  fontSize: '18px',
  fontStyle: 'normal',
  fontWeight: 700,
  lineHeight: 'normal',
  background: 'linear-gradient(90deg, #1A56AB 0%, #28B6BF 99.95%)',
  WebkitBackgroundClip: 'text',
  backgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  textFillColor: 'transparent',
  marginLeft: '7px'
}

const partitionDivStyle = {
  justifyContent: 'center',
  display: 'grid',
}

export class ExploreComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showOther: true,
      dynamicContent: null,
      showPreviousApp: false,
      dynamicContent2: null,
      activeIndex: -1,
      sharedState:true,
    };
    this.updateStateFromLender = this.updateStateFromLender.bind(this);
  }

  handleClick1 = () => {
    this.setState({ showOther: !this.state.showOther });
  };

  updateStateFromLender(newValue) {
    this.setState({ sharedState: newValue });
  }


  render() {
    return (
      <>
        <section style={{ backgroundColor: '#E8F2FF', paddingBottom: '20px' }}>
        {this.state.sharedState && <div>
          <div style={{ ...partitionDivStyle, marginBottom: '0px', paddingTop: '32px' }}><img src={partitionLine} /></div>
          <div id="exploreOtherOffers" style={{ display: 'flex', justifyContent: 'center', paddingTop: '32px', cursor: 'pointer' }}>
            <div style={currentAppBoxStyle} onClick={this.handleClick1}>
              <span style={{ marginLeft: '12px' }}>
                {this.state.showOther ? (<img src={downwardArrow} />) : (<img src={rightArrow} />)}
              </span>
              <span style={currentAppTextStyle}>Explore Other Offers</span>
            </div>
          </div>
          {this.state.showOther && (<div style={{ display: 'flex', justifyContent: 'center' }}>
            <div style={{ width: '90%' }}>
              <LenderComponent callerComp="ExploreComponent" onUpdate={this.updateStateFromLender}/>
            </div>
          </div>)}</div>}
        </section>
      </>
    )
  }
}

export default ExploreComponent
