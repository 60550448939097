import React, { Component } from 'react'

import HeaderComponent from '../header/HeaderComponent'
import AboutUSPageBody from '../body/AboutUSPageBody'
import FooterComponent from '../footer/FooterComponent'

export class AboutUsPage extends Component {
  render() {
    return (
      <>
      <div style={{background: '#F8FBFF', overflowX:'hidden',overflowY:'hidden'}}>
      <HeaderComponent/>
      <AboutUSPageBody/>
      <FooterComponent/>
      </div>
      </>
    )
  }
}

export default AboutUsPage;